import { DateNow, DateTimeNow, sumDaysInDate, SumHoursInDateTime, TimestampNow } from '../../../../utils/dates'

export const GetStatusClient = (statusClient) => {
  switch (statusClient) {
    case 'active':
      return { value: 'Ativo', color: 'is-success', action: [['Inativar', 'trash', 'is-danger']] }
    case 'disabled':
      return { value: 'Inativo', color: 'is-danger', action: [['Ativar', 'check', 'is-success']] }
    case 'deleted':
      return { value: 'Deletado', color: 'is-dark', action: [['Recuperar', 'checkout', 'is-success']] }
    case 'suspended':
      return { value: 'Suspenso', color: 'is-light', action: [['Ativar', 'check', 'is-success']] }
    default:
      return { value: 'Não reconhecido', color: 'is-light', action: [['Recuperar', 'checkout', 'is-success']] }
  }
}

export const translateStatusInvoice = (status) => {
  const response = {
    createOS: ['Criando Ordem de serviço', 'is-grey'],
    reCreateOS: ['Recriando Ordem de serviço', 'is-grey'],
    awaitBilling: ['Aguardando Faturamento', 'is-warning'],
    billing: ['Faturado, aguardando prefeitura', 'is-warning'],
    successBilling: ['Faturado com sucesso', 'is-success'],
    canceled: ['Cancelado', 'is-dark'],
    failure: ['Falha no faturamento', 'is-danger'],
  }

  return response[status]
}

export const createSchemaClient = (values) => {
  return {
    responsavel_telefone: values.responsavel_telefone,
    cpfCnpj: values.cpfCnpj,
    ...(values.cpfCnpj.length > 15 && {
      ...(values?.isncMunicipal && { isncMunicipal: values?.isncMunicipal || '' }),
      ...(values?.isncEstadual && { isncEstadual: values.isncEstadual }),
      ...(values?.tipoAtividade && { tipoAtividade: values.tipoAtividade }),
      ...(values?.cnae && { cnae: values.cnae }),
    }),
    razaoSocial: values.razaoSocial,
    nomeFantasia: values.nomeFantasia,
    cep: values.cep,
    endereco: values.endereco,
    bairro: values.bairro,
    uf: values.uf,
    municipio: values.municipio,
    endereco_numero: values.endereco_numero,
    complemento: values?.complemento || '',
    reponsavel_name: values.reponsavel_name,
    responsavel_cpf: values.responsavel_cpf,
    responsavel_email: values.responsavel_email,
    infoBanks_banco: values.infoBanks_banco,
    infoBanks_agencia: values.infoBanks_agencia,
    infoBanks_conta: values.infoBanks_conta,
    infoBanks_name_titular: values.infoBanks_name_titular,
    infoBanks_cpf_titular: values.infoBanks_cpf_titular,
    infoBanks_pix: values.infoBanks_pix,
    dt_invoices: values.dt_invoices,
    type_invoices: values.type_invoices,
  }
}

export const createSchemaClientPlans = (values) => {
  return {
    name: values.name,
    index: values.index,
    lastModifield: DateTimeNow(), // utc
    modifieldBy: values.modifieldBy,
    idPlan: values.id,
    idOmie: values.idOmie,
    value: values.value,
    typeExpired: values.typeExpired,
    dtStart: values.dtStart,
    dtEnd: values?.dtEnd || '',
  }
}

export const createSchemaOrdemDeServicoOmie = (values) => {
  return {
    Cabecalho: {
      cCodIntOS: values.idInvoce,
      cCodParc: '000', // Pagamento A Vista
      cEtapa: values.startColumnOmie,
      dDtPrevisao: sumDaysInDate(DateNow(), values.dayPayment),
      nCodCli: values.idClientOmie,
      nQtdeParc: 1,
    },
    Departamentos: [],
    Email: {
      ...(values.type_invoices === 'boleto' && { cEnvBoleto: 'S' }),
      ...(values.type_invoices === 'pix' && { cEnvPix: 'S' }),
      cEnvLink: 'S',
      cEnviarPara: values.responsavel_email,
    },
    InformacoesAdicionais: {
      cDadosAdicNF: values.descNF,
      cCodCateg: values.codCategoria,
      nCodCC: values.nContaCorrente,
    },
    ServicosPrestados: [
      {
        nCodServico: values.plan.idOmie,
        nQtde: 1,
        nValUnit: values.plan.value,
      },
    ],
  }
}

export const createSchemaOrdemDeServico = (values) => {
  return {
    dataOmie: values.dataOmie,
    idClient: values.idClient,
    idClientOmie: values.idClientOmie,
    idPlan: values.plan.id,
    idPlanOmie: values.plan.idOmie,
    dt_created: SumHoursInDateTime(DateTimeNow(), +3), // Data/hora de criação da OS
    valueOS: values.plan.value, // Valor da OS
    // Status:
    // "createOS", "awaitingBilling", "billing",
    // "sucessBilling", "failure"
    status: 'createOS',
    descricao: values.plan.name,
    billingAttempts: 0, // Número de tentativas de faturamento
    billingError: JSON.stringify([
      [
        `Pedido de criação de OS feita por: ${values.userName}`,
        TimestampNow() + 3 * 60 * 60 * 1000,
        'dailyCreateOSOmie',
      ],
    ]),
    dt_nextInvoice: values.dt_nextInvoice,
  }
}

export const getActivePlan = (plans, today) => {
  // Ordena os planos por `dtStart` em
  // ordem decrescente (mais recente primeiro)
  console.log(plans)
  const sortedPlans = plans.sort((a, b) => new Date(b.dtStart) - new Date(a.dtStart))

  // Filtra os planos ativos
  for (const plan of sortedPlans) {
    const dtStart = new Date(plan.dtStart)
    const dtEnd = plan.dtEnd ? new Date(plan.dtEnd) : null

    // Verifica se está dentro do intervalo
    // de datas ou se é um plano sem término
    if (today >= dtStart && (!dtEnd || today <= dtEnd)) {
      return plan // Retorna o primeiro plano ativo encontrado
    }
  }

  // Retorna null se nenhum plano estiver ativo
  return null
}

export const colorTagStatusPagamentos = (status) => {
  const colors = {
    'A vencer': 'is-warning',
    'Pagamento Realizado': 'is-success',
    Cancelado: 'is-danger',
  }

  return colors[status]
}

export const validateNewCollaboratorSchema = (newCollaborator) => {
  // Verifica se o valor de objeto existe ou se é truthy
  const isValidSchema = Object.entries(newCollaborator).every((item) => {
    return item[1]
  })

  const fieldsToFix = Object.entries(newCollaborator).filter((item) => {
    return !item[1]
  })

  let error = null

  if (fieldsToFix.length && fieldsToFix.length > 0) {
    // Cria uma mensagem formatada para o campo com o erro
    // Obs: criar para os demais campos
    switch (fieldsToFix[0][0]) {
      case 'name':
        error = 'Nome'
        break
      case 'photoUrl':
        error = 'Foto de perfil'
        break
      default:
        error = 'Desconhecido'
        break
    }
  }

  return { isValidSchema, error }
}
