import React from 'react'

import { Column } from '../../../../components/layouts/columns'
import { Input } from '../../../../components/ui/input'
import { Select } from '../../../../components/ui/select'
import { Icon } from '../../../../components/icons'
import { Button } from '../../../../components/ui/button'
import useFormAddJobOffers from './hooks/useFormAddJobOffers'
import { TransformationforMask } from '../../../../utils/masks'

export const ClientsFormAddJobOffers = ({ loading, options, disabledList }) => {
  const {
    formContext,
    SetNewValue,
    SetNewValueForm,
    AddNewQuestInForm,
    SetDownQuest,
    SetUpQuest,
    DeleteQuetInForm,
    vestmentList,
    AddVestments,
    typeResponseList,
  } = useFormAddJobOffers()

  return (
    <div>
      <Column.Root>
        <Column.Content>
          <Input.Root>
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
              Data da Vaga
            </Input.Label>
            <Input.Contents>
              <Input.Prompt
                className="is-rounded"
                value={formContext?.formJobOffer?.date}
                setValue={(value) => {
                  SetNewValue(value, 'date')
                }}
                required={true}
                disabled={disabledList?.date}
                type={'date'}
              ></Input.Prompt>
            </Input.Contents>
          </Input.Root>
        </Column.Content>
        <Column.Content>
          <Input.Root className="">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
              Tipo de Vaga
            </Input.Label>
            <Select.Root>
              <Select.Content
                color="is-grey"
                className="is-fullwidth is-rounded"
                value={formContext?.formJobOffer?.idPosition}
                setValue={(value) => {
                  SetNewValue(value, 'idPosition')
                }}
                disabled={disabledList?.idPosition}
                required={true}
              >
                <Select.Option value={''} title={'Selecione um cargo...'} />
                {options.map((job) => {
                  return <Select.Option key={`option_${job.id}`} value={job.id} title={job.nome} />
                })}
              </Select.Content>
            </Select.Root>
          </Input.Root>
        </Column.Content>
      </Column.Root>

      <Column.Root>
        <Column.Content>
          <Input.Root className="">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
              Tipo do Registro
            </Input.Label>
            <Select.Root>
              <Select.Content
                color="is-grey"
                className="is-fullwidth is-rounded"
                value={formContext?.formJobOffer?.register}
                setValue={(value) => {
                  SetNewValue(value, 'register')
                }}
                disabled={disabledList?.register}
                required={true}
              >
                <Select.Option value={null} title={''} />
                <Select.Option value={'Não Registrado'} title={'Não Registrado'} />
                <Select.Option value={'Registro Intermitente'} title={'Registro Intermitente'} />
                <Select.Option value={'Registro Por Tempo determinado'} title={'Registro Por Tempo determinado'} />
                <Select.Option value={'Registro Por Tempo indeterminado'} title={'Registro Por Tempo indeterminado'} />
              </Select.Content>
            </Select.Root>
          </Input.Root>
        </Column.Content>
        <Column.Content>
          <Input.Root>
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
              Quantidade da Staff
            </Input.Label>
            <Input.Contents alignIcon={'left'}>
              <Input.Prompt
                className="is-rounded"
                value={formContext?.formJobOffer?.amountJob}
                setValue={(value) => {
                  SetNewValue(value, 'amountJob')
                }}
                required={true}
                disabled={disabledList?.amountJob}
                type="number"
              ></Input.Prompt>
              <Input.Icon align="is-left">
                <Icon size={20}>users</Icon>
              </Input.Icon>
            </Input.Contents>
          </Input.Root>
        </Column.Content>
      </Column.Root>

      {/* Cachê da vaga */}
      <Column.Root>
        <Column.Content>
          <Input.Root>
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
              Cache
            </Input.Label>
            <Input.Contents alignIcon={'left'}>
              <Input.Prompt
                className="is-rounded"
                value={formContext?.formJobOffer?.value}
                setValue={(value) => {
                  SetNewValue(value, 'value')
                }}
                required={true}
                disabled={disabledList?.value}
                type="number"
              ></Input.Prompt>
              <Input.Icon align="is-left">R$</Input.Icon>
            </Input.Contents>
          </Input.Root>
        </Column.Content>

        <Column.Content>
          <Input.Root className="">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
              Pagamento
            </Input.Label>
            <Select.Root>
              <Select.Content
                color="is-grey"
                className="is-fullwidth is-rounded"
                value={formContext?.formJobOffer?.payment}
                setValue={(value) => {
                  SetNewValue(value, 'payment')
                }}
                disabled={disabledList?.payment}
                required={true}
              >
                <Select.Option value={null} title={''} />
                <Select.Option value={'pix'} title={'Pix'} />
                <Select.Option value={'dinheiro'} title={'Dinheiro'} />
                <Select.Option value={'transferencia'} title={'Transferência Bancaria'} />
              </Select.Content>
            </Select.Root>
          </Input.Root>
        </Column.Content>
        <Column.Content>
          <Input.Root>
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
              Data Pagamento
            </Input.Label>
            <Input.Contents>
              <Input.Prompt
                className="is-rounded"
                value={formContext?.formJobOffer?.dtPayment}
                setValue={(value) => {
                  SetNewValue(value, 'dtPayment')
                }}
                required={true}
                disabled={disabledList?.dtPayment}
                type="date"
              ></Input.Prompt>
            </Input.Contents>
          </Input.Root>
        </Column.Content>
      </Column.Root>

      {/* Horario da vaga */}
      <Column.Root>
        <Column.Content>
          <Input.Root className="">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
              Horário Inicio
            </Input.Label>
            <Input.Contents>
              <Input.Prompt
                className="is-rounded"
                value={formContext?.formJobOffer?.hrStart}
                setValue={(value) => {
                  SetNewValue(value, 'hrStart')
                }}
                required={true}
                disabled={disabledList?.hrStart}
                type={'time'}
              ></Input.Prompt>
            </Input.Contents>
          </Input.Root>
        </Column.Content>
        <Column.Content>
          <Input.Root className="">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
              Horário Check-in
            </Input.Label>
            <Input.Contents>
              <Input.Prompt
                className="is-rounded"
                value={formContext?.formJobOffer?.hrCheckin}
                setValue={(value) => {
                  SetNewValue(value, 'hrCheckin')
                }}
                required={true}
                disabled={disabledList?.hrCheckin}
                type={'time'}
              ></Input.Prompt>
            </Input.Contents>
          </Input.Root>
        </Column.Content>
        <Column.Content>
          <Input.Root className="">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
              Horário Check-out
            </Input.Label>
            <Input.Contents>
              <Input.Prompt
                className="is-rounded"
                value={formContext?.formJobOffer?.hrCheckout}
                setValue={(value) => {
                  SetNewValue(value, 'hrCheckout')
                }}
                required={true}
                disabled={disabledList?.hrCheckout}
                type={'time'}
              ></Input.Prompt>
            </Input.Contents>
          </Input.Root>
        </Column.Content>
      </Column.Root>

      {/* Transport */}
      <Column.Root>
        <Column.Content className={formContext?.formJobOffer?.transportAllowance === '2' ? 'is-5' : ''}>
          <Input.Root>
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
              Vale Transporte
            </Input.Label>
            <Select.Root>
              <Select.Content
                color="is-grey"
                className="is-fullwidth is-rounded"
                value={formContext?.formJobOffer?.transportAllowance}
                setValue={(value) => {
                  SetNewValue(value, 'transportAllowance')
                }}
                disabled={disabledList?.transportAllowance}
                required={true}
              >
                <Select.Option value={null} title={''} />
                <Select.Option value={0} title={'Não Fornece'} />
                <Select.Option value={1} title={'Fornece'} />
                <Select.Option value={2} title={'Incluso no cachê'} />
              </Select.Content>
            </Select.Root>
          </Input.Root>
        </Column.Content>
        {formContext?.formJobOffer?.transportAllowance === '2' && (
          <Column.Content>
            <Input.Root>
              <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
                Valor do Vale Transporte
              </Input.Label>
              <Input.Contents alignIcon={'left'}>
                <Input.Prompt
                  className="is-rounded"
                  value={formContext?.formJobOffer?.transportAllowance_value}
                  setValue={(value) => {
                    SetNewValue(value, 'transportAllowance_value')
                  }}
                  required={true}
                  disabled={disabledList?.transportAllowance_value}
                  type="number"
                ></Input.Prompt>
                <Input.Icon align="is-left">R$</Input.Icon>
              </Input.Contents>
            </Input.Root>
          </Column.Content>
        )}
      </Column.Root>

      {/* Food */}
      <Column.Root>
        <Column.Content className={formContext?.formJobOffer?.food === '2' ? 'is-5' : ''}>
          <Input.Root>
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
              Alimentação
            </Input.Label>
            <Select.Root>
              <Select.Content
                color="is-grey"
                className="is-fullwidth is-rounded"
                value={formContext?.formJobOffer?.food}
                setValue={(value) => {
                  SetNewValue(value, 'food')
                }}
                disabled={disabledList?.food}
                required={true}
              >
                <Select.Option value={null} title={''} />
                <Select.Option value={0} title={'Não Fornece'} />
                <Select.Option value={1} title={'Fornece'} />
                <Select.Option value={2} title={'Incluso no cachê'} />
              </Select.Content>
            </Select.Root>
          </Input.Root>
        </Column.Content>
        {formContext?.formJobOffer?.food === '2' && (
          <Column.Content>
            <Input.Root>
              <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
                Valor da alimentação
              </Input.Label>
              <Input.Contents alignIcon={'left'}>
                <Input.Prompt
                  className="is-rounded"
                  value={formContext?.formJobOffer?.food_value}
                  setValue={(value) => {
                    SetNewValue(value, 'food_value')
                  }}
                  required={true}
                  disabled={disabledList?.food_value}
                  type="number"
                ></Input.Prompt>
                <Input.Icon align="is-left">R$</Input.Icon>
              </Input.Contents>
            </Input.Root>
          </Column.Content>
        )}
      </Column.Root>

      {/* Vestments */}
      <Column.Root>
        <Column.Content className={formContext?.formJobOffer?.vestments === '1' ? 'is-4' : ''}>
          <Input.Root>
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
              Fornece Vestimentas?
            </Input.Label>
            <Select.Root>
              <Select.Content
                color="is-grey"
                className="is-fullwidth is-rounded"
                value={formContext?.formJobOffer?.vestments}
                setValue={(value) => {
                  SetNewValue(value, 'vestments')
                }}
                disabled={disabledList?.vestments}
                required={true}
              >
                <Select.Option value={null} title={''} />
                <Select.Option value={0} title={'Não Fornece'} />
                <Select.Option value={1} title={'Fornece'} />
              </Select.Content>
            </Select.Root>
          </Input.Root>
        </Column.Content>
        {formContext?.formJobOffer?.vestments === '1' && (
          <Column.Content>
            <Column.Root>
              <Column.Content className="is-8">
                <Input.Root>
                  <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
                    Qual parte será fornecida?
                  </Input.Label>
                  <Select.Root>
                    <Select.Content
                      color="is-grey"
                      className="is-fullwidth is-rounded"
                      value={formContext?.formJobOffer?.selectVestment}
                      setValue={(value) => {
                        SetNewValue(value, 'selectVestment')
                      }}
                    >
                      <Select.Option value={''} title={'Selecione uma vestimenta...'} />
                      {vestmentList
                        .sort((a, b) => {
                          return a.localeCompare(b)
                        })
                        .map((vest) => {
                          return <Select.Option key={vest} value={vest} title={TransformationforMask(vest, 'title')} />
                        })}
                    </Select.Content>
                  </Select.Root>
                </Input.Root>
              </Column.Content>
              <Column.Content size="is-1">
                <Button.Bt
                  className="mt-5 is-rounded"
                  color="is-dark"
                  loading={loading}
                  action={async () => {
                    await AddVestments(formContext?.formJobOffer?.selectVestment)
                    // HandleAddVestments(formContext?.selectVestment)
                  }}
                >
                  Adicionar
                </Button.Bt>
              </Column.Content>
            </Column.Root>
          </Column.Content>
        )}
      </Column.Root>

      {/* Formulario */}
      {(formContext?.formJobOffer?.form || []).length === 0 && (
        <Column.Root className="mt-5">
          <Column.Content>
            <Button.Root align="right">
              {
                <Button.Content>
                  <Button.Bt
                    className=""
                    type={'button'}
                    loading={loading}
                    action={() => {
                      AddNewQuestInForm()
                    }}
                  >
                    Adicionar Formulário
                  </Button.Bt>
                </Button.Content>
              }
            </Button.Root>
          </Column.Content>
        </Column.Root>
      )}
      {(formContext?.formJobOffer?.form || []).length > 0 && (
        <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
          Formulário Pesonalizado
        </Input.Label>
      )}
      {(formContext?.formJobOffer?.form || []).map((item, index) => {
        return (
          <div
            key={`cr_${index}`}
            className="box mt-1"
            style={{
              borderColor: 'lightgrey',
              borderWidth: 1,
              borderStyle: 'solid', // Adicione esta linha para ativar a borda
            }}
          >
            <Column.Root>
              <Column.Content size="is-1">
                {/* Botão para mover para cima */}
                <Button.Bt
                  className="mb-6"
                  type="button"
                  loading={loading}
                  action={() => {
                    SetUpQuest(Number(index))
                  }}
                  disabled={Number(index) <= 0}
                >
                  <Icon size={25} margin={-10}>
                    arrowUp
                  </Icon>
                </Button.Bt>

                {/* Botão para mover para baixo */}
                <Button.Bt
                  className="mt-3"
                  type="button"
                  loading={loading}
                  disabled={Number(index) >= (formContext?.formJobOffer?.form || []).length - 1}
                  action={() => {
                    SetDownQuest(Number(index))
                  }}
                >
                  <Icon size={25} margin={-10}>
                    arrowDown
                  </Icon>
                </Button.Bt>
              </Column.Content>

              <Column.Content style={{ flexDirection: 'column' }}>
                <Input.Root className="">
                  <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
                    Pergunta
                  </Input.Label>
                  <Input.Contents alignIcon={'left'}>
                    <Input.Prompt
                      className="is-rounded"
                      color="is-light"
                      value={item?.title}
                      setValue={(value) => {
                        SetNewValueForm(value, 'title', index)
                      }}
                      required={true}
                      disabled={disabledList?.hrCheckin}
                    ></Input.Prompt>
                    <Input.Icon>{`${index + 1}. `}</Input.Icon>
                  </Input.Contents>
                </Input.Root>
                <Input.Root className="">
                  <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
                    Tipo de Reposta
                  </Input.Label>
                  <Input.Contents>
                    <Select.Root className={`is-rounded ${formContext?.inEdition?.state ? 'is-danger' : ''}`}>
                      <Select.Content
                        color={formContext?.inEdition?.state ? 'is-danger' : 'is-grey'}
                        className="is-fullwidth is-rounded"
                        value={item?.valueType}
                        setValue={(value) => {
                          SetNewValueForm(value, 'valueType', index)
                        }}
                        disabled={false}
                        required={true}
                      >
                        {typeResponseList.map(({ value, title, selected, disabled }) => (
                          <option key={value} value={value} selected={selected} disabled={disabled}>
                            {title}
                          </option>
                        ))}
                      </Select.Content>
                    </Select.Root>
                  </Input.Contents>
                </Input.Root>
              </Column.Content>

              <Column.Content size="is-1 mr-3">
                <Button.Bt
                  className="mt-6"
                  type={'button'}
                  color="is-danger"
                  loading={loading}
                  action={() => {
                    DeleteQuetInForm(index)
                  }}
                >
                  <Icon size={20} margin={-5}>
                    trash
                  </Icon>
                </Button.Bt>
              </Column.Content>
            </Column.Root>
          </div>
        )
      })}
      {(formContext?.formJobOffer?.form || []).length > 0 && (
        <Column.Root className="mt-5">
          <Column.Content>
            <Button.Root align="right">
              <Button.Content>
                <Button.Bt
                  className=""
                  type={'button'}
                  loading={loading}
                  action={() => {
                    AddNewQuestInForm()
                  }}
                >
                  Adicionar nova pergunta
                </Button.Bt>
              </Button.Content>
            </Button.Root>
          </Column.Content>
        </Column.Root>
      )}
    </div>
  )
}
