import React from 'react'

import { Column } from '../../../../../components/layouts/columns'
import { Button } from '../../../../../components/ui/button'
import { Input } from '../../../../../components/ui/input'
import { Select } from '../../../../../components/ui/select'
import { Path } from '../../../../../router/paths'

export const FormDocuments = ({ values, setValues, loading, disabledList }) => {
  return (
    <>
      <Column.Root>
        <Column.Content>
          <Input.Root className="mb-5">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required>
              Nome do Documento
            </Input.Label>
            <Input.Contents>
              <Input.Prompt
                className="is-rounded"
                value={values}
                setValue={setValues}
                field={'name'}
                disabled={disabledList?.name}
                required
              ></Input.Prompt>
            </Input.Contents>
          </Input.Root>

          <Input.Root className="mb-5">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required>
              Enviar durante...
            </Input.Label>
            <Select.Root>
              <Select.Content
                color="is-grey"
                className="is-fullwidth is-rounded"
                value={values}
                setValue={setValues}
                field={'sendInRegister'}
                disabled={disabledList?.type}
                required
              >
                <Select.Option value={null} title={''} />
                <Select.Option value={true} title={'Cadastro inicial do colaborador'} />
                <Select.Option value={false} title={'Adição de novo cargo'} />
              </Select.Content>
            </Select.Root>
          </Input.Root>

          <Input.Root className="mb-5">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required>
              Instrução/Regras do envio
            </Input.Label>
            <Input.Textarea value={values} setValue={setValues} field={'dica'} disabled={disabledList?.dica} required />
          </Input.Root>

          <Input.Root className="mb-5">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required>
              Tipo de Coleta
            </Input.Label>
            <Select.Root>
              <Select.Content
                color="is-grey"
                className="is-fullwidth is-rounded"
                value={values}
                setValue={setValues}
                field={'type'}
                disabled={disabledList?.type}
                required
              >
                <Select.Option value={null} title={''} />
                <Select.Option value={'capture'} title={'Tirar Foto'} />
                <Select.Option value={'galery'} title={'Selecionar da Galeria'} />
                <Select.Option value={'capture&galery'} title={'Tirar foto ou Selecionar da Galeria'} />
                <Select.Option value={'doc'} title={'Selecionar Documento (PDF, etc...)'} />
                <Select.Option value={'input'} title={'Enviar Texto'} />
              </Select.Content>
            </Select.Root>
          </Input.Root>

          {(values?.type || '').includes('capture') && (
            <Input.Root className="mb-5">
              <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required>
                Tipo de Camera
              </Input.Label>
              <Select.Root>
                <Select.Content
                  color="is-grey"
                  className="is-fullwidth is-rounded"
                  value={values}
                  setValue={setValues}
                  field={'facing'}
                  disabled={disabledList?.type}
                  required
                >
                  <Select.Option value={null} title={''} />
                  <Select.Option value={'back'} title={'Camera Traseira'} />
                  <Select.Option value={'front'} title={'Camera Frontal'} />
                  <Select.Option value={'select'} title={'Deixar usuário escolher'} />
                </Select.Content>
              </Select.Root>
            </Input.Root>
          )}

          {(values?.type || '').includes('input') && (
            <Input.Root className="mb-5">
              <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required>
                Tipo de Teclado
              </Input.Label>
              <Select.Root>
                <Select.Content
                  color="is-grey"
                  className="is-fullwidth is-rounded"
                  value={values}
                  setValue={setValues}
                  field={'keyboardType'}
                  disabled={disabledList?.type}
                  required
                >
                  <Select.Option value={null} title={''} />
                  <Select.Option value={'default'} title={'Padrão'} />
                  <Select.Option value={'numeric'} title={'Numérico'} />
                  <Select.Option value={'email-address'} title={'E-mail'} />
                </Select.Content>
              </Select.Root>
            </Input.Root>
          )}
        </Column.Content>
      </Column.Root>
      <Column.Root className="mt-5 mb-6">
        <Column.Content>
          <Button.Root align="right">
            <Button.Content>
              <Button.A color="is-light" link={Path.AdminDocuments} loading={loading}>
                Cancelar
              </Button.A>
            </Button.Content>
            <Button.Content>
              <Button.Bt className="" color="is-success" colorText="has-text-white" type={'submit'} loading={loading}>
                Salvar
              </Button.Bt>
            </Button.Content>
          </Button.Root>
        </Column.Content>
      </Column.Root>
    </>
  )
}
