export const getCollaboratorStatusType = (collaboratorStatusNumber) => {
  let statusType

  switch (collaboratorStatusNumber) {
    case 0:
      statusType = 'Em Criação'
      break
    case 1:
      statusType = 'Em Analise'
      break
    case 2:
      statusType = 'Em Correção'
      break
    case 3:
      statusType = 'Bloqueado'
      break
    case 4:
      statusType = 'Inativo'
      break
    case 5:
      statusType = 'Pendente Pagamento'
      break
    case 6:
      statusType = 'Analise de Cargo'
      break
    case 9:
      statusType = 'Ativo'
      break
    default:
      statusType = 'não reconhecido'
      break
  }

  return statusType
}

export const getCollaboratorFavoriteStatusType = (collaboratorStatus, reason = null) => {
  let statusType
  let statusColor

  if (!collaboratorStatus && reason) {
    statusType = 'Convite recusado'
    statusColor = 'is-danger'
  } else if (!collaboratorStatus && !reason) {
    statusType = 'Convite enviado'
    statusColor = 'is-warning'
  } else if (collaboratorStatus && !reason) {
    statusType = 'Convite aceito'
    statusColor = 'is-success'
  } else {
    statusType = 'Status desconhecido'
    statusColor = ''
  }

  return {
    statusType,
    statusColor,
  }
}
