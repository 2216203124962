import { useEffect, useState } from 'react'

import { useGlobalContext } from '../../../../../context/ContextGlobal'
import { deleteDocument, getDocuments } from '../../../../../firebase/firestore'
import { TryCatch } from '../../../../../utils/general'

const usePosition = () => {
  const [search, setSearch] = useState('')

  const {
    user,
    setAlerts,
    dataContext,
    setDataContext,
    // infosContext,
    // setInfosContext,
    // formContext,
    // setFormContext,
    loading,
    setLoading,
    // statusLoading,
    // setStatusLoading,
  } = useGlobalContext()

  const cargos = dataContext?.cargos || []

  // coleta uma lista de planos
  const getData = async () => {
    const getDocumentos = await getDocuments('documentos')
    const getConsentimentos = await getDocuments('consentimentos')
    const getCargos = await getDocuments('cargos')

    setDataContext({
      documentos: getDocumentos,
      consentimentos: getConsentimentos,
      cargos: getCargos,
    })
  }

  const FetchGetData = async () => {
    await TryCatch(getData, setLoading, setAlerts)
  }

  // deleta o usuario no cliente e se não tiver cadastro em cliente, ele apaga o usuario do autenticador
  const delPosition = async (position) => {
    if (!user?.user?.permissions?.cargos_excluir) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Permissão Negada',
          msg: 'Você não possui permissão para apagar Cargos',
        },
      ])
    }
    await deleteDocument('cargos', position)
    await getData()
    return setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: 'Cargo Excluído',
        msg: 'Cargo excluído da sua base de dados',
      },
    ])
  }

  // executa quando carrega a pagina e quando é alterado o getUserList
  useEffect(() => {
    getData()
  }, [])

  const cargFiltered =
    search !== ''
      ? Object.values(cargos).filter((carg) => {
          const searchString = `${carg.nome}|${carg.descricao}`.toLowerCase()
          return searchString.includes(search.toLocaleLowerCase())
        })
      : Object.values(cargos)

  return { search, loading, cargFiltered, delPosition, setSearch, FetchGetData }
}

export default usePosition
