// import { Button } from '../../../../components/ui/button'
import { Modal } from '../../../../components/ui/modal'
import { ClientsFormAddJobOffers } from '../_form/clientsFormAddJobOffers'
import { Button } from '../../../../components/ui/button'
import { Form } from '../../../../components/ui/form'
import { useModalAddjobOffers } from './hooks/useModalAddjobOffers'
import { Icon } from '../../../../components/icons'
import { CopyText } from '../../../../utils/general'

const ModalAddJobOffers = ({ setActive, active = true }) => {
  const {
    loading,
    formContext,
    setFormContext,
    infosContext,
    HandleAddJobOffers,
    HandleEditJobOffers,
    HandleDelValuesFormJob,
  } = useModalAddjobOffers(setActive)

  if (active) {
    return (
      <Form.Root
        action={async () => {
          if (formContext?.formJobOffer?.id) {
            await HandleEditJobOffers()
          } else {
            await HandleAddJobOffers()
          }
        }}
        className="mb-6 mt-1"
      >
        <Modal.Root active={active}>
          <Modal.Contents witdh={'80%'}>
            <Modal.Header>
              <p className="modal-card-title" style={{ marginBottom: -15 }}>
                {formContext?.formJobOffer?.id ? 'Editar Vagas' : 'Adicionar Vagas'}
                {formContext?.formJobOffer?.id && (
                  <>
                    <p className="tag is-ligth ml-2">Id: {formContext?.formJobOffer?.id}</p>
                    <Button.Bt
                      className="is-small mt-1 ml-1"
                      action={() => {
                        CopyText(formContext?.formJobOffer?.id)
                      }}
                    >
                      <Icon size={10}>copy</Icon>
                    </Button.Bt>
                  </>
                )}
              </p>
              <Modal.HeaderClose setActive={setActive}></Modal.HeaderClose>
            </Modal.Header>

            <Modal.Body>
              <ClientsFormAddJobOffers
                loading={loading}
                formContext={formContext}
                setFormContext={setFormContext}
                options={infosContext?.positions || []}
                disabledList={{}}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button.Root align="right">
                {!formContext?.formJobOffer?.id && (
                  <Button.Content>
                    <Button.Bt
                      className=""
                      type={'button'}
                      loading={loading}
                      action={async () => {
                        await HandleDelValuesFormJob()
                      }}
                    >
                      Limpar
                    </Button.Bt>
                  </Button.Content>
                )}

                <Button.Content>
                  <Button.Bt
                    className=""
                    color="is-success"
                    colorText="has-text-white"
                    type={'submit'}
                    loading={loading}
                  >
                    {formContext?.formJobOffer?.id ? 'Salvar' : 'Adicionar'}
                  </Button.Bt>
                </Button.Content>
              </Button.Root>
            </Modal.Footer>
          </Modal.Contents>
        </Modal.Root>
      </Form.Root>
    )
  } else {
    return <></>
  }
}

export default ModalAddJobOffers
