import { useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'

import { useGlobalContext } from '../../../../../context/ContextGlobal'
import { EditUser } from '../../../../../utils/requests'
import { TryCatch } from '../../../../../utils/general'
import { Path } from '../../../../../router/paths'
import { getSubDocument } from '../../../../../firebase/firestore'
import { schemaUserKeap } from '../utils/schemas'
import { ClearFormContext } from '../utils/clearData'

const useAdminEditUser = () => {
  const { uid } = useParams()
  const {
    user,
    setAlerts,
    // dataContext,
    // setDataContext,
    infosContext,
    // setInfosContext,
    formContext,
    setFormContext,
    loading,
    setLoading,
    // statusLoading,
    // setStatusLoading,
  } = useGlobalContext()

  const clientId = user.client.id

  const navigate = useNavigate()

  const getForm = async () => {
    const getUserData = await getSubDocument('clientes', clientId, 'users', uid)
    console.log(getUserData)
    setFormContext(getUserData)
  }

  const FetchGetForm = async () => {
    await TryCatch(getForm, setLoading, setAlerts)
  }

  const editUser = async () => {
    const newUserKeap = schemaUserKeap({ ...formContext, uid })
    await EditUser(newUserKeap, clientId, setAlerts)
    await ClearFormContext(setFormContext)
    return navigate(Path.AdminUsers)
  }

  const handleEditUser = async () => {
    await TryCatch(editUser, setLoading, setAlerts)
  }

  useEffect(() => {
    FetchGetForm()
  }, [])

  return { uid, user, loading, formContext, infosContext, setFormContext, handleEditUser }
}

export default useAdminEditUser
