import { useEffect, useState } from 'react'

import { useGlobalContext } from '../../../../../context/ContextGlobal'
import { getDocuments, deleteDocument } from '../../../../../firebase/firestore'
import { TryCatch } from '../../../../../utils/general'

const useDocuments = () => {
  const {
    user,
    setAlerts,
    dataContext,
    setDataContext,
    // infosContext, todas as informações para a página funcionar
    // setInfosContext,
    // formContext,
    setFormContext,
    loading,
    setLoading,
    // statusLoading,
    // setStatusLoading,
  } = useGlobalContext()
  const [search, setSearch] = useState('')
  const documentos = dataContext?.documentos || []

  // coleta uma lista de planos
  const getData = async () => {
    const getCargos = await getDocuments('cargos')
    const getDocumentos = await getDocuments('documentos')
    const newDocumentos = getDocumentos.map((item) => {
      const inCargo = getCargos.some((cargos) => {
        const documentosIds = cargos?.documentos || []
        const found = documentosIds.includes(item.id)
        return found
      })
      return { ...item, blocked: inCargo } // Adiciona se ele é bloqueado para deletar ou não
    })

    setDataContext({ documentos: newDocumentos })
  }

  const FetchGetData = async () => {
    await TryCatch(getData, setLoading, setAlerts)
  }

  const deleteDocumentation = async (document) => {
    if (!user?.user?.permissions?.documentos_excluir) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Permissão Negada',
          msg: 'Você não possui permissão para apagar Documentos',
        },
      ])
    }

    const blocked = document?.blocked
    if (blocked) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Documento em uso',
          msg: 'Você não pode deletar esse documentos, pois ele está em uso por algum cargo',
        },
      ])
    }

    const idDocument = document?.id || null

    if (!idDocument) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'ID do documento não encontrado',
          msg: 'Não conseguimos achar o ID do documento. Tente novamente mais tarde.',
        },
      ])
    }

    await deleteDocument('documentos', idDocument)
    await getData()
    return setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: 'Documento Excluído',
        msg: 'Documento excluído da sua base de dados',
      },
    ])
  }

  const handleDeleteDocument = async (document) => {
    await TryCatch(
      async () => {
        deleteDocumentation(document)
      },
      setLoading,
      setAlerts,
    )
  }

  const docsFiltered =
    search !== ''
      ? Object.values(documentos).filter((docs) => {
          const searchString = `${docs.nome}|${docs.descricao}`.toLowerCase()
          return searchString.includes(search.toLocaleLowerCase())
        })
      : Object.values(documentos)

  // executa quando carrega a pagina e quando é auterado o getUserList
  useEffect(() => {
    FetchGetData()
    setFormContext({})
  }, [])

  return { search, setSearch, loading, docsFiltered, FetchGetData, handleDeleteDocument }
}

export default useDocuments
