import React from 'react'

import { Page } from '../../../components/layouts/page'
import { Icon } from '../../../components/icons'
import { Column } from '../../../components/layouts/columns'
import { Button } from '../../../components/ui/button'
import { Table } from '../../../components/ui/table'
import { Input } from '../../../components/ui/input'
import { Path } from '../../../router/paths'
import { useClientEvents } from './_hooks/useClientEvents'
import { TransformationforMask } from '../../../utils/masks'
import { PaginationFunctional } from '../../../components/ui/pagination/PaginationFunctional'
import { Form } from '../../../components/ui/form'
import { Select } from '../../../components/ui/select'

export const ClientEvents = () => {
  const {
    page,
    setPage,
    statusEvent,
    setStatusEvent,
    navigate,
    loading,
    search,
    setSearch,
    dataContext,
    FetchGetDatas,
    FetchGetInfos,
    HandleDeleteEvent,
    // HandleLockUnlockEvent,
    HandleCopyEvent,
  } = useClientEvents()

  return (
    <>
      <Page.Root className="is-fullwidth">
        {/* Titulo e Botões */}
        <Column.Root className="mt-6 pt-5">
          <Column.Content>
            <p className="subtitle is-size-3">Eventos</p>
          </Column.Content>
          <Column.Content className="has-text-right">
            <Button.Root align="right">
              <Button.Content>
                <Button.Bt
                  color="is-dark"
                  colorText="has-text-white"
                  action={() => {
                    FetchGetDatas()
                    FetchGetInfos()
                  }}
                  loading={loading}
                >
                  <Icon size={15}>sync</Icon>
                </Button.Bt>
              </Button.Content>
              <Button.Content>
                <Button.A color="is-success" colorText="has-text-white" link={Path.ClientAddEvents} loading={loading}>
                  <Icon size={15}>add</Icon>
                </Button.A>
              </Button.Content>
            </Button.Root>
          </Column.Content>
        </Column.Root>
        {/* Barra de pesquisa */}
        <Form.Root
          action={() => {
            FetchGetDatas()
          }}
        >
          <Column.Root className="is-mobile">
            <Column.Content>
              <Input.Root className="mt-5">
                <Input.Contents alignIcon={'left'}>
                  <Input.Prompt value={search} setValue={setSearch} placeholder="Persquisar..."></Input.Prompt>
                  <Input.Icon align="is-left">
                    <Icon size={20}>search</Icon>
                  </Input.Icon>
                </Input.Contents>
              </Input.Root>
            </Column.Content>
            <Column.Content size="is-2">
              <Input.Root>
                <Input.Label className="has-text-grey-light" style={{ marginBottom: 0 }}>
                  Situação
                </Input.Label>
                <Select.Root>
                  <Select.Content value={statusEvent} setValue={setStatusEvent} className="is-fullwidth">
                    <Select.Option key={'01'} title="Todos" value={'todos'} />
                    <Select.Option key={'02'} title="Aberto" value={'open'} />
                    <Select.Option key={'03'} title="Finalizado" value={'closed'} />
                    <Select.Option key={'04'} title="Excluidos" value={'deleted'} />
                  </Select.Content>
                </Select.Root>
              </Input.Root>
            </Column.Content>
          </Column.Root>
        </Form.Root>

        {/* Tabela de dados */}
        <p className="has-text-danger">
          Faltando fazer:
          <br />- Implementar Bloqueios por permissão
          <br />- implementar BACKEND enviar notificação ao DELETAR evento e apagar JOBOFFERS E SALVAR INFORMAÇÕES NA
          CANDIDATURA DO COLABORADOR
          <br />- implementar enviar notificações
        </p>

        {loading ? (
          <Icon>loading</Icon>
        ) : (
          <>
            {dataContext?.events && (
              <>
                <Column.Root className="is-mobile">
                  <Column.Content>
                    <Table.Root className="is-fullwidth is-hoverable is-narrow has-text-centered is-striped">
                      <Table.Head>
                        <Table.Row key={'table_events_head'}>
                          <Table.Cell size={300}>Nome</Table.Cell>
                          <Table.Cell>Criado em</Table.Cell>
                          <Table.Cell>Cidade</Table.Cell>
                          <Table.Cell>UF</Table.Cell>
                          <Table.Cell>Situação</Table.Cell>
                          <Table.Cell size={200}>Ações</Table.Cell>
                        </Table.Row>
                      </Table.Head>
                      {(dataContext?.events?.data || []).length > 0 ? (
                        (dataContext?.events?.data || []).map((value) => {
                          return (
                            <>
                              <Table.Row
                                key={value.id}
                                onclick={() => {
                                  navigate(Path.ClientEditEvents(value.id))
                                }}
                                // se o evento tiver a data de pagamento menos que a data de hoje, e ainda estive aberto, então ele alerta usuario
                                // className={`${value.status === 'open' ? 'has-background-warning-light' : ''}`}
                              >
                                <Table.Cell className="is-size-7 is-vcentered">
                                  {TransformationforMask(value.name, 'title')}
                                </Table.Cell>
                                <Table.Cell className="is-size-7 is-vcentered">{value.dtCreated}</Table.Cell>
                                <Table.Cell className="is-size-7 is-vcentered">{value.address.city}</Table.Cell>
                                <Table.Cell className="is-size-7 is-vcentered">{value.address.uf}</Table.Cell>
                                <Table.Cell className="is-size-7 is-vcentered">
                                  {/* Status do evento */}
                                  <p
                                    className={`tag ${
                                      value.status === 'open'
                                        ? 'is-success'
                                        : value.status === 'closed'
                                          ? 'is-danger'
                                          : 'is-dark'
                                    }`}
                                  >
                                    {value.status === 'open'
                                      ? 'Aberto'
                                      : value.status === 'closed'
                                        ? 'Finalizado'
                                        : 'Excluido'}
                                  </p>
                                </Table.Cell>
                                <Table.Cell className="is-vcentered">
                                  {value.status === 'open' ? (
                                    <div style={{ marginBottom: 5, marginTop: 5 }}>
                                      <Button.Root align="centered">
                                        {/* <Button.Content>
                                          <Button.Bt
                                            color={value.openAllJobs ? 'is-danger' : 'is-success'}
                                            colorText={'has-text-white'}
                                            link={Path.ClientEditSectors(value.id)}
                                            loading={loading}
                                            title={value.openAllJobs ? 'Fechar Candidaturas' : 'Abrir Candidaturas'}
                                            action={async () => {
                                              await HandleLockUnlockEvent(value.openAllJobs, value.id, value.name)
                                            }}
                                          >
                                            {value.openAllJobs ? (
                                              <Icon size={18}>lock</Icon>
                                            ) : (
                                              <Icon size={18}>unlock</Icon>
                                            )}
                                          </Button.Bt>
                                        </Button.Content> */}
                                        <Button.Content>
                                          <Button.Bt
                                            color="is-light"
                                            action={async () => {
                                              await HandleCopyEvent(value.id)
                                            }}
                                            loading={loading}
                                          >
                                            <Icon size={18}>copy</Icon>
                                          </Button.Bt>
                                        </Button.Content>
                                        <Button.Content>
                                          <Button.Bt
                                            color="is-danger"
                                            colorText={'has-text-white'}
                                            action={() => {
                                              HandleDeleteEvent(value.id, value.name)
                                            }}
                                            loading={loading}
                                          >
                                            <Icon size={18}>trash</Icon>
                                          </Button.Bt>
                                        </Button.Content>
                                      </Button.Root>
                                    </div>
                                  ) : (
                                    <div style={{ marginBottom: 5, marginTop: 5 }}>
                                      <Button.Root align="centered">
                                        <Button.Content>
                                          <Button.Bt
                                            color="is-danger"
                                            colorText={'has-text-white'}
                                            disabled={true}
                                            loading={loading}
                                          >
                                            <Icon size={18}>trash</Icon>
                                          </Button.Bt>
                                        </Button.Content>
                                      </Button.Root>
                                    </div>
                                  )}
                                </Table.Cell>
                              </Table.Row>
                            </>
                          )
                        })
                      ) : (
                        <></>
                      )}

                      {(dataContext?.events?.data || []).length === 0 && !loading ? (
                        <p className="has-text-grey">Ops... não encontramos eventos criado por sua empresa</p>
                      ) : (
                        <></>
                      )}

                      <Table.Body></Table.Body>
                    </Table.Root>
                  </Column.Content>
                </Column.Root>
                <PaginationFunctional
                  paginationData={dataContext?.events?.pagination}
                  setValuePage={setPage}
                  valuePage={page}
                  setFetchData={FetchGetDatas}
                ></PaginationFunctional>
              </>
            )}
          </>
        )}
      </Page.Root>
    </>
  )
}
