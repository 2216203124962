import { useParams } from 'react-router-dom'
import { useCallback } from 'react'

import { useGlobalContext } from '../../../../../context/ContextGlobal'
import {
  addSubDocument,
  getDocuments,
  getSubDocuments,
  updateArrayInDocument,
  updateSubDocument,
} from '../../../../../firebase/firestore'
import { TryCatch } from '../../../../../utils/general'
import { SetHistoryEvent } from '../../../../../utils/requests'

export const useModalAddjobOffers = (setActiveModal) => {
  const {
    user,
    setAlerts,
    loading,
    setLoading,
    dataContext,
    setDataContext, // contexto que guarda dados de lista para a pagina
    infosContext,
    setInfosContext, // contexto que guarda dados adicionais para a pagina funcionar
    formContext,
    setFormContext, // contexto que guarda dados de formularios da pagina
  } = useGlobalContext()

  const { id } = useParams()

  // const navigate = useNavigate()
  const getDatas = async () => {
    const getJobOffers = await getSubDocuments('events', id, 'jobOffers')

    setDataContext((prev) => ({ ...prev, jobOffers: getJobOffers }))
  }

  const getInfos = async () => {
    const getPositions = await getDocuments('cargos')
    const positionsById = getPositions.reduce((acc, curr) => {
      acc[curr.id] = curr.nome
      return acc
    }, {})
    console.log(positionsById)
    setInfosContext((prev) => ({ ...prev, positions: getPositions, positionsById }))
  }

  const FetchGetdatas = useCallback(async () => {
    await TryCatch(getDatas, setLoading, setAlerts)
  }, [])

  const FetchGetInfos = useCallback(async () => {
    await TryCatch(getInfos, setLoading, setAlerts)
  }, [])

  const schemaJobOffer = (values) => {
    if (values === undefined) {
      setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Campos Vazios',
          msg: 'Os Campos obrigatórios estão vazios, preencha-os corretamente e tente novamente.',
        },
      ])
      return {}
    }

    const vestments = values?.vestments || '0'
    const form = values?.form || []

    if (vestments === '1') {
      const hasSelectVestmentsInForm = form.some((item) => item.type === 'vestments')
      if (!hasSelectVestmentsInForm) {
        setAlerts((prev) => [
          ...prev,
          {
            type: 'error',
            title: 'Vestimentas não selecionadas',
            msg: 'Selecione uma vestimenta para criar uma pergunta no formulario.',
          },
        ])
        return {}
      }
    }

    return {
      amountJob: values?.amountJob || '0',
      date: values?.date || '',
      hrCheckin: values?.hrCheckin || '',
      hrCheckout: values?.hrCheckout || '',
      hrStart: values?.hrStart || '',
      idPosition: values?.idPosition || '',
      status: values?.status || 'closed',
      value: values?.value || '0',
      vestments,
      food: values?.food || '0',
      food_value: values?.food_value || '0',
      transportAllowance: values?.transportAllowance || '0',
      transportAllowance_value: values?.transportAllowance_value || '0',
      register: values?.register || '',
      payment: values?.payment || '',
      dtPayment: values?.dtPayment || '',
      form,
    }
  }

  const addJobOffers = async () => {
    const idEvent = id

    // se não possuir a permissão
    if (!user.user.permissions.jobOffers_add) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Permissão Negada',
          msg: 'Você não possui permissão para realizar essa ação.',
        },
      ])
    }

    const newJob = { ...schemaJobOffer(formContext?.formJobOffer), filledJob: 0 }

    if (Object.keys(newJob).length === 0) {
      return
    }

    const dateExists = (dataContext?.jobOffers || []).some((jobOffers) => jobOffers.date === newJob.date)
    const positionExists = (dataContext?.jobOffers || []).some(
      (jobOffers) => jobOffers.idPosition === newJob.idPosition,
    )

    if (dateExists && positionExists) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Vaga Já Existente',
          msg: `A vaga que está tentando adicionar já existe.`,
        },
      ])
    }

    const idJobOffers = await addSubDocument('events', idEvent, 'jobOffers', newJob)
    const idsPosition = [newJob.idPosition]
    console.log('idsPosition: ', idsPosition)
    await updateArrayInDocument('events', idEvent, 'idPositions', idsPosition)

    setDataContext((prev) => ({ ...prev, jobOffers: [...(prev?.jobOffers || []), { id: idJobOffers, ...newJob }] }))

    await deleteValuesFormJob()
    await FetchGetdatas()
    await FetchGetInfos()
    await SetHistoryEvent(
      idEvent,
      user.user.nome,
      `Vaga Criada: ${infosContext.positionsById[newJob.idPosition]} - ${newJob.date}`,
    )
    setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: 'Vaga Adicionada',
        msg: `Vaga adicionada ao evento ${formContext?.name} com sucesso.`,
      },
    ])

    return setActiveModal(false)
  }

  const editJobOffers = async () => {
    const idEvent = id
    const idJobOffer = formContext?.formJobOffer?.id || null

    if (!idJobOffer) {
      setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'idJobOffer não encontrado',
          msg: 'Acesse novamente o evento para coletar as informações necessárias e tente novamente realizar a ação anterior.',
        },
      ])
      return setActiveModal(false)
    }

    if (!user.user.permissions.jobOffers_edit) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Permissão Negada',
          msg: 'Você não possui permissão para realizar essa ação.',
        },
      ])
    }

    const reason = window.prompt('Justifique o motivo da edição da vaga:')

    if (reason === null || reason === '') {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Justificativa obrigatória',
          msg: 'Para editar esta vaga, é necessario informar a justificativa.',
        },
      ])
    }

    const newJob = schemaJobOffer(formContext?.formJobOffer)

    if (Object.keys(newJob).length === 0) {
      return
    }

    await updateSubDocument('events', idEvent, 'jobOffers', idJobOffer, newJob)
    await updateArrayInDocument('events', idEvent, 'idPositions', [newJob.idPosition])

    const newJobsData = (dataContext?.jobOffers || []).filter((job) => job.id !== idJobOffer)
    console.log(newJobsData)

    await deleteValuesFormJob()
    await FetchGetdatas()
    await FetchGetInfos()

    await SetHistoryEvent(
      idEvent,
      user.user.nome,
      `Vaga Edita: ${infosContext.positionsById[newJob.idPosition]} - ${newJob.date} ${reason !== '' && `|| Motivo: ${reason}`}`,
    )

    setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: 'Vaga Editada',
        msg: `Vaga Editada com sucesso.`,
      },
    ])

    return setActiveModal(false)
  }

  const deleteValuesFormJob = async () => {
    const newJob = schemaJobOffer({})

    if (Object.keys(newJob).length === 0) {
      return
    }

    setFormContext((prev) => ({ ...prev, formJobOffer: newJob }))
  }

  const HandleAddJobOffers = async () => {
    await TryCatch(
      () => {
        addJobOffers()
      },
      setLoading,
      setAlerts,
    )
  }

  const HandleEditJobOffers = async (idJob) => {
    await TryCatch(
      () => {
        editJobOffers(idJob)
      },
      setLoading,
      setAlerts,
    )
  }

  const HandleDelValuesFormJob = async () => {
    await TryCatch(deleteValuesFormJob, setLoading, setAlerts)
  }

  return {
    loading,
    formContext,
    setFormContext,
    infosContext,
    HandleAddJobOffers,
    HandleEditJobOffers,
    HandleDelValuesFormJob,
    schemaJobOffer,
    FetchGetInfos,
    FetchGetdatas,
  }
}
