import { useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'

import { useGlobalContext } from '../../../../../context/ContextGlobal'
import { getDocument, setDocument } from '../../../../../firebase/firestore'
import { Path } from '../../../../../router/paths'
import { TryCatch } from '../../../../../utils/general'

const useEditDocuments = () => {
  const { id } = useParams()
  const {
    user,
    setAlerts,
    // dataContext,
    // setDataContext,
    // infosContext,
    // setInfosContext,
    formContext,
    setFormContext,
    loading,
    setLoading,
    // statusLoading,
    // setStatusLoading,
  } = useGlobalContext()

  const navigate = useNavigate()

  const schemaDocumentation = (value) => {
    return {
      created_by: value?.create_by || '',
      dt_created: value?.dt_created || '',
      name: value?.name || value?.nome || '',
      descricao: value?.descricao || '',
      type: value?.type || '',
      dica: value?.dica || '',
      facing: value?.facing || '',
      keyboardType: value?.keyboardType || '',
      sendInRegister: value?.sendInRegister || false,
    }
  }

  const getForm = async () => {
    const getDocumentation = await getDocument('documentos', id)
    const newDocumentation = schemaDocumentation(getDocumentation)
    setFormContext(newDocumentation)
  }

  const fetchGetForm = async () => {
    await TryCatch(getForm, setLoading, setAlerts)
  }

  const editDocumentation = async () => {
    const newDocumentation = schemaDocumentation(formContext)
    await setDocument('documentos', id, newDocumentation)

    setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: 'Documento Editado',
        msg: `O Documento "${newDocumentation.name}" foi editado com sucesso.`,
      },
    ])

    setFormContext({})
    return navigate(Path.AdminDocuments)
  }

  const handleEditDocuments = async () => {
    await TryCatch(editDocumentation, setLoading, setAlerts)
  }

  useEffect(() => {
    fetchGetForm()
  }, [])

  return { user, loading, formContext, setFormContext, handleEditDocuments }
}

export default useEditDocuments
