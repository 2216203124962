import React from 'react'

import { Icon } from '../../../../components/icons'
import { Column } from '../../../../components/layouts/columns'
import { Button } from '../../../../components/ui/button'
import { Table } from '../../../../components/ui/table'
import { Input } from '../../../../components/ui/input'
import { Page } from '../../../../components/layouts/page'
import { Path } from '../../../../router/paths'
import useAdminUsers from './hooks/useAdminUsers'

export const AdminUsers = () => {
  const {
    sectors,
    loading,
    search,
    usersFiltered,
    setSearch,
    FetchGetData,
    FetchGetInfos,
    handleBlockUnblockUserKeap,
    handleDelUserKeap,
  } = useAdminUsers()

  return (
    <>
      <Page.Root className="is-fullwidth">
        <Column.Root className="mt-6 pt-5">
          <Column.Content>
            <p className="subtitle is-size-3">Usuários</p>
          </Column.Content>
          <Column.Content className="has-text-right">
            <Button.Root align="right">
              <Button.Content>
                <Button.Bt
                  color="is-dark"
                  colorText="has-text-white"
                  action={() => {
                    FetchGetData()
                    FetchGetInfos()
                  }}
                  loading={loading}
                >
                  <Icon size={15}>sync</Icon>
                </Button.Bt>
              </Button.Content>
              <Button.Content>
                <Button.A
                  className=""
                  color="is-success"
                  colorText="has-text-white"
                  link={Path.AAddUsers}
                  loading={loading}
                >
                  +
                </Button.A>
              </Button.Content>
            </Button.Root>
          </Column.Content>
        </Column.Root>
        <Column.Root className="">
          <Column.Content>
            <Input.Root>
              <Input.Contents alignIcon={'left'}>
                <Input.Prompt value={search} setValue={setSearch} placeholder="Persquisar..."></Input.Prompt>
                <Input.Icon align="is-left">
                  <Icon size={20}>search</Icon>
                </Input.Icon>
              </Input.Contents>
            </Input.Root>
          </Column.Content>
        </Column.Root>
        <Column.Root className="">
          <Column.Content>
            <Table.Root className="is-fullwidth is-hoverable is-narrow has-text-centered ">
              <Table.Head>
                <Table.Row>
                  <Table.Cell>Status</Table.Cell>
                  <Table.Cell>Nome</Table.Cell>
                  <Table.Cell>E-mail</Table.Cell>
                  <Table.Cell>Cargo</Table.Cell>
                  <Table.Cell>Setor</Table.Cell>
                  <Table.Cell>Ações</Table.Cell>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {usersFiltered.map((value, index) => {
                  return (
                    <>
                      <Table.Row>
                        <Table.Cell className="is-size-7 is-vcentered">
                          {value.status ? (
                            <p className="tag  is-success is-rounded">Ativo</p>
                          ) : (
                            <p className="tag is-danger is-rounded">Bloqueado</p>
                          )}
                        </Table.Cell>
                        <Table.Cell className="is-size-7 is-vcentered">{value.nome}</Table.Cell>
                        <Table.Cell className="is-size-7 is-vcentered">{value.email}</Table.Cell>
                        <Table.Cell className="is-size-7 is-vcentered">{value.cargo}</Table.Cell>
                        <Table.Cell className="is-size-7 is-vcentered">
                          {sectors.find((setor) => setor.id === value.sectorId)?.nome}
                        </Table.Cell>
                        <Table.Cell className="is-vcentered">
                          <div style={{ marginBottom: -10, marginTop: 5 }}>
                            <Button.Root align="centered">
                              <Button.Content>
                                <Button.A
                                  size="is-small"
                                  color="is-warning"
                                  link={Path.AEditUsers(value.id)}
                                  loading={loading}
                                >
                                  <Icon size={15}>pen</Icon>
                                </Button.A>
                              </Button.Content>
                              <Button.Content>
                                {value.status ? (
                                  <Button.Bt
                                    size="is-small"
                                    color="is-danger"
                                    action={() => {
                                      handleBlockUnblockUserKeap(value.id)
                                    }}
                                    loading={loading}
                                  >
                                    <Icon size={15}>lock</Icon>
                                  </Button.Bt>
                                ) : (
                                  <Button.Bt
                                    size="is-small"
                                    color="is-success"
                                    action={() => {
                                      handleBlockUnblockUserKeap(value.id)
                                    }}
                                    loading={loading}
                                  >
                                    <Icon size={15}>unlock</Icon>
                                  </Button.Bt>
                                )}
                              </Button.Content>
                              <Button.Content>
                                <Button.Bt
                                  size="is-small"
                                  color="is-danger"
                                  action={() => {
                                    handleDelUserKeap(value.id)
                                  }}
                                  loading={loading}
                                >
                                  <Icon size={15}>trash</Icon>
                                </Button.Bt>
                              </Button.Content>
                            </Button.Root>
                          </div>
                        </Table.Cell>
                      </Table.Row>
                    </>
                  )
                })}
              </Table.Body>
            </Table.Root>
          </Column.Content>
        </Column.Root>
      </Page.Root>
    </>
  )
}
