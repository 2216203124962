import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

import { useGlobalContext } from '../../../../../context/ContextGlobal'
import { DateTimeNow } from '../../../../../utils/dates'
import { NewUser } from '../../../../../utils/requests'
import { Path } from '../../../../../router/paths'
import { TryCatch } from '../../../../../utils/general'
import { ClearFormContext } from '../utils/clearData'

const useAdminAddUser = () => {
  const {
    user,
    setAlerts,
    // dataContext,
    // setDataContext,
    infosContext,
    // setInfosContext,
    formContext,
    setFormContext,
    loading,
    setLoading,
    // statusLoading,
    // setStatusLoading,
  } = useGlobalContext()

  const clientId = user.client.id

  const navigate = useNavigate()

  const addUser = async () => {
    const newUserInfo = {
      ...formContext,
      createBy: user.user.nome,
      dtCreated: DateTimeNow(),
      type: 'admin',
    }
    const response = await NewUser(newUserInfo, clientId, setAlerts)

    if (response?.uid) {
      setAlerts((prev) => [
        ...prev,
        {
          title: 'Usuário criado',
          msg: `Usuario criado com sucesso, acesse com o e-mail: ${newUserInfo.email} e senha "keap123"`,
          type: 'success',
        },
      ])
      await ClearFormContext(setFormContext)
      return navigate(Path.AdminUsers)
    }
  }

  const handleAddUser = async () => {
    await TryCatch(addUser, setLoading, setAlerts)
  }

  useEffect(() => {
    ClearFormContext(setFormContext)
  }, [])

  return { user, loading, formContext, setFormContext, infosContext, handleAddUser }
}

export default useAdminAddUser
