import { Input } from '../../../../components/ui/input'
import { Icon } from '../../../../components/icons'
import { Column } from '../../../../components/layouts/columns'
import { Button } from '../../../../components/ui/button'
import { Modal } from '../../../../components/ui/modal'
import { ValidateCollaboratorModalForm } from '../_components/validateCollaboratorModalForm'
import { useValidateCollaboratorModal } from '../_hooks/useValidateCollaboratorModal'
import { useValidateCollaboratorModalForm } from '../_hooks/useValidateCollaboratorModalForm'

export const ValidateCollaboratorModal = ({ active, setActive }) => {
  const { loading, handleSendFieldsToFix, modalCollaboratorSubmitDisabled } = useValidateCollaboratorModal()

  const { handleUpdateAllEditReasons } = useValidateCollaboratorModalForm()

  return (
    <form>
      <Modal.Root active={active}>
        <Modal.Contents>
          <Modal.Header>
            <p className="modal-card-title" style={{ marginBottom: -15 }}>
              Validação dos campos
            </p>
            <Modal.HeaderClose setActive={setActive}></Modal.HeaderClose>
          </Modal.Header>
          <Modal.Body>
            <ValidateCollaboratorModalForm />
          </Modal.Body>
          <Modal.Footer className="is-justify-content-end">
            {/* {console.log(values?.favorite)} */}
            {/* Motivo geral para todos os contatos de emergência e itens do objeto */}
            <Column.Root className="is-mobile">
              <Column.Content className="is-full">
                <div className="field">
                  <Input.Label className="has-text-grey pl-5" style={{ marginBottom: 0 }}>
                    Motivo Geral
                  </Input.Label>
                  <div className="is-flex w-100 gap-2">
                    <input
                      id="general-reason"
                      className={`input is-grey is-rounded ${loading ? 'is-loading' : ''}`}
                      type="text"
                      onChange={(e) => handleUpdateAllEditReasons(e)} // Atualiza o motivo geral
                    />
                    <Input.Error>(se aplica para todos os campos não preenchidos)</Input.Error>
                  </div>
                </div>
              </Column.Content>
            </Column.Root>
            <Button.Bt
              // color={values?.favorite ? 'is-danger' : 'is-light'}
              type="button"
              colorText="has-text-white"
              color="is-success"
              title="Favoritar"
              action={handleSendFieldsToFix}
              disabled={modalCollaboratorSubmitDisabled}
              loading={loading}
            >
              Enviar campos para correção
              <Icon size={20}>check</Icon>
            </Button.Bt>
          </Modal.Footer>
        </Modal.Contents>
      </Modal.Root>
    </form>
  )
}
