import { useNavigate } from 'react-router-dom'

import { useGlobalContext } from '../../../../../context/ContextGlobal'
import { TryCatch } from '../../../../../utils/general'
import { DateTimeNow } from '../../../../../utils/dates'
import { addDocument } from '../../../../../firebase/firestore'
import { Path } from '../../../../../router/paths'

const useAddConsents = () => {
  const {
    user,
    setAlerts,
    // dataContext,
    // setDataContext,
    // infosContext,
    // setInfosContext,
    formContext,
    setFormContext,
    loading,
    setLoading,
    // statusLoading,
    // setStatusLoading,
  } = useGlobalContext()

  const navigate = useNavigate()

  const addConsent = async () => {
    const newConsent = {
      ...formContext,
      created_by: user?.user?.nome,
      dt_created: DateTimeNow(),
    }
    await addDocument('consentimentos', newConsent)
    setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: 'Consentimento Criado',
        msg: 'Novo Consentimento criado com sucesso.',
      },
    ])
    setFormContext({})
    return navigate(Path.AdminConsents)
  }

  const HandleAddConsents = async () => {
    await TryCatch(addConsent, setLoading, setAlerts)
  }

  return { user, loading, setLoading, formContext, setFormContext, HandleAddConsents }
}

export default useAddConsents
