import React from 'react'

import { Page } from '../../../../components/layouts/page'
import { Column } from '../../../../components/layouts/columns'
import { Form } from '../../../../components/ui/form'
import { FormConsents } from './form/formConsents'
import useAddConsents from './hooks/useAddConsents'

export const AdminAddConsents = () => {
  const { loading, formContext, setFormContext, HandleAddConsents } = useAddConsents()

  return (
    <Page.Section className="is-fullheight-with-navbar">
      <Form.Root
        action={() => {
          HandleAddConsents()
        }}
      >
        <Column.Root>
          <Column.Content>
            <p className="subtitle is-size-3">Adicionar Consentimento</p>
          </Column.Content>
        </Column.Root>
        <FormConsents loading={loading} values={formContext} setValues={setFormContext} disabledList={{}} />
      </Form.Root>
    </Page.Section>
  )
}
