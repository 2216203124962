import { useParams } from 'react-router-dom'
import { useEffect } from 'react'

import { useGlobalContext } from '../../../../context/ContextGlobal'
import { getDocument, updateDocument } from '../../../../firebase/firestore'
import { TryCatch } from '../../../../utils/general'
import { SetHistoryEvent } from '../../../../utils/requests'

export const useClientEditEvents = () => {
  const {
    user,
    setAlerts,
    loading,
    setLoading,
    // dataContext,
    // setDataContext, // contexto que guarda dados de lista para a pagina
    // infosContext,
    // setInfosContext, // contexto que guarda dados adicionais para a pagina funcionar
    formContext,
    setFormContext, // contexto que guarda dados de formularios da pagina
  } = useGlobalContext()

  const { id } = useParams()

  const getDatas = async () => {
    const getEventData = await getDocument('events', id)
    const eventData = {
      ...getEventData,
      ...getEventData.address,
      statusEvent: getEventData.status,
    }
    console.log(eventData)
    setFormContext((prev) => ({ ...prev, ...eventData }))
    console.log(user.user.permissions)
  }

  const editEvent = async (idEvent) => {
    if (!user.user.permissions.events_edit) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Permissão Negada',
          msg: 'Você não possui permissão para realizar essa ação.',
        },
      ])
    }

    const reason = window.prompt('Justifique o motivo da edição do evento:')

    if (reason === null || reason === '') {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Justificativa obrigatória',
          msg: 'Para editar as informações deste evento, é necessario informar a justificativa.',
        },
      ])
    }

    const newEvent = {
      name: formContext?.name,
      round: formContext?.round,
      address: {
        endereco: formContext?.endereco,
        city: formContext?.city,
        uf: formContext?.uf,
        local_encontro: formContext?.local_encontro,
      },
      infoAdditional: formContext?.infoAdditional || '',
      ...(formContext?.hasCityRestrictions && { cityRestrictions: formContext?.cityRestrictions || [] }),
      hasCityRestrictions: formContext?.hasCityRestrictions || false,
    }

    await SetHistoryEvent(id, user.user.nome, `Evento Editado ${reason !== '' && `|| Motivo: ${reason}`}`)

    await updateDocument('events', idEvent, newEvent)
    await setFormContext({})

    setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: 'Evento Editado',
        msg: `O evento ${newEvent.name} foi editado com sucesso.`,
      },
    ])

    window.location.reload(true)
  }

  const HandleEditEvents = async (id) => {
    await TryCatch(
      () => {
        editEvent(id)
      },
      setLoading,
      setAlerts,
    )
  }

  const FetchGetDatas = async () => {
    await TryCatch(getDatas, setLoading, setAlerts)
  }

  useEffect(() => {
    FetchGetDatas()
  }, [])

  return { id, loading, formContext, setFormContext, HandleEditEvents }
}
