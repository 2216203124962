import React from 'react'

import { Column } from '../../../../../components/layouts/columns'
import { Button } from '../../../../../components/ui/button'
import { Input } from '../../../../../components/ui/input'
import { Select } from '../../../../../components/ui/select'
import { Path } from '../../../../../router/paths'

export const FormConsents = ({ values, setValues, loading, disabledList }) => {
  return (
    <>
      <Column.Root>
        <Column.Content>
          <Input.Root className="mb-5">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
              Nome do Termo
            </Input.Label>
            <Input.Contents>
              <Input.Prompt
                className="is-rounded"
                value={values}
                setValue={setValues}
                field={'name'}
                required={true}
                disabled={disabledList?.nome}
              ></Input.Prompt>
            </Input.Contents>
          </Input.Root>

          <Input.Root className="mb-5">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required>
              Assinar durante...
            </Input.Label>
            <Select.Root>
              <Select.Content
                color="is-grey"
                className="is-fullwidth is-rounded"
                value={values}
                setValue={setValues}
                field={'sendInRegister'}
                disabled={disabledList?.type}
                required
              >
                <Select.Option value={null} title={''} />
                <Select.Option value={true} title={'Cadastro inicial do colaborador'} />
                <Select.Option value={false} title={'Adição de novo cargo'} />
              </Select.Content>
            </Select.Root>
          </Input.Root>

          <Input.Root className="mb-5">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
              Termo
            </Input.Label>
            <Input.Contents>
              <Input.Textarea
                className="is-rounded"
                value={values}
                setValue={setValues}
                field={'termo'}
                required={true}
                disabled={disabledList?.termo}
              ></Input.Textarea>
            </Input.Contents>
          </Input.Root>

          <Input.Root className="mb-5">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
              Mensagem de Confirmação
            </Input.Label>
            <Input.Contents>
              <Input.Prompt
                className="is-rounded"
                value={values}
                setValue={setValues}
                field={'txtButton'}
                required={true}
                disabled={disabledList?.termo}
              ></Input.Prompt>
            </Input.Contents>
          </Input.Root>

          {/* <Input.Root className="mb-5">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
              tipo deAssinatura
            </Input.Label>
            <Select.Root>
              <Select.Content
                color="is-grey"
                className="is-fullwidth is-rounded"
                value={values}
                setValue={setValues}
                field={'type_assinatura'}
                disabled={disabledList?.type_assinatura}
              >
                <Select.Option value={null} title={''} />
                <Select.Option value={'check'} title={'Aceite de Termos'} />
                <Select.Option value={'digital'} title={'Assinatura Digital'} />
              </Select.Content>
            </Select.Root>
          </Input.Root> */}

          <Input.Root className="mb-5">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
              Revogar
            </Input.Label>
            <Select.Root>
              <Select.Content
                color="is-grey"
                className="is-fullwidth is-rounded"
                value={values}
                setValue={setValues}
                field={'revogar'}
                disabled={disabledList?.revogar}
              >
                <Select.Option value={null} title={''} />
                <Select.Option value={30} title={'Á cada 30 Dias'} />
                <Select.Option value={60} title={'Á cada 60 Dias'} />
                <Select.Option value={90} title={'Á cada 90 Dias'} />
                <Select.Option value={180} title={'Á cada 180 Dias'} />
                <Select.Option value={365} title={'Á cada 365 Dias'} />
                <Select.Option value={'not_expired'} title={'Não Revogar'} />
              </Select.Content>
            </Select.Root>
          </Input.Root>
        </Column.Content>
      </Column.Root>
      <Column.Root className="mt-5 mb-6">
        <Column.Content>
          <Button.Root align="right">
            <Button.Content>
              <Button.A color="is-light" link={Path.AdminConsents} loading={loading}>
                Cancelar
              </Button.A>
            </Button.Content>
            <Button.Content>
              <Button.Bt className="" color="is-success" colorText="has-text-white" type={'submit'} loading={loading}>
                Salvar
              </Button.Bt>
            </Button.Content>
          </Button.Root>
        </Column.Content>
      </Column.Root>
    </>
  )
}
