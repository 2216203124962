import { useParams, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

import { useGlobalContext } from '../../../../../context/ContextGlobal'
import { Path } from '../../../../../router/paths'
import { getDocument, updateDocument } from '../../../../../firebase/firestore'
import { TryCatch } from '../../../../../utils/general'

const useEditConsents = () => {
  const { id } = useParams()
  const {
    // user,
    setAlerts,
    // dataContext, tudo que for dados da página, e.g: listas
    // setDataContext,
    // infosContext, todas as informações para a página funcionar
    // setInfosContext,
    formContext,
    setFormContext,
    loading,
    setLoading,
    // statusLoading,
    // setStatusLoading,
  } = useGlobalContext()

  const navigate = useNavigate()

  const schemaConsent = (value) => {
    return {
      created_by: value?.create_by || '',
      dt_created: value?.dt_created || '',
      name: value?.name || '',
      revogar: value?.revogar || '',
      termo: value?.termo || '',
      txtButton: value?.txtButton || '',
    }
  }

  const getForm = async () => {
    const getConsent = await getDocument('consentimentos', id)
    const newConsent = schemaConsent(getConsent)
    setFormContext(newConsent)
  }

  const fetchGetForm = async () => {
    await TryCatch(getForm, setLoading, setAlerts)
  }

  const editConsent = async () => {
    const newConsentimento = schemaConsent(formContext)
    await updateDocument('consentimentos', id, newConsentimento)

    setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: 'Consentimento Editado',
        msg: `O Consentimento "${newConsentimento?.name || ''}" foi editado com sucesso.`,
      },
    ])

    setFormContext({})
    return navigate(Path.AdminConsents)
  }

  const handleEditConsent = async () => {
    await TryCatch(editConsent, setLoading, setAlerts)
  }

  useEffect(() => {
    fetchGetForm()
  }, [])

  return { loading, formContext, setFormContext, handleEditConsent }
}

export default useEditConsents
