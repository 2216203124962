import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'

import { useGlobalContext } from '../../../../context/ContextGlobal'
import {
  deleteSubDocument,
  getSubDocumentsWithQuerys,
  updateArrayInDocument,
  updateSubDocument,
} from '../../../../firebase/firestore'
import { TryCatch } from '../../../../utils/general'
import { SetHistoryEvent } from '../../../../utils/requests'
import { useModalAddjobOffers } from '../_modal/hooks/useModalAddjobOffers'

export const useClientEventsjobOffers = () => {
  const {
    user,
    setAlerts,
    loading,
    setLoading,
    dataContext,
    // setDataContext, // contexto que guarda dados de lista para a pagina
    infosContext,
    setInfosContext, // contexto que guarda dados adicionais para a pagina funcionar
    formContext,
    setFormContext, // contexto que guarda dados de formularios da pagina
  } = useGlobalContext()

  const { schemaJobOffer, FetchGetInfos, FetchGetdatas } = useModalAddjobOffers()

  const { id } = useParams()

  const [search, setSearch] = useState('')

  const [modal, setModal] = useState(false)
  const [addVagas, setAddVagas] = useState(false)
  const [idJobEdit, SetIdJobEdit] = useState(null)

  const deleteJobOffers = async (idJob) => {
    if (!user.user.permissions.jobOffers_delete) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Permissão Negada',
          msg: 'Você não possui permissão para realizar essa ação.',
        },
      ])
    }
    const reason = window.prompt('Informe a justificativa da Exclusão dessa vaga: ')

    if (reason === null || reason === '') {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Justificativa obrigatória',
          msg: `Para Deletar essa vaga, é necessario informar a justificativa.`,
        },
      ])
    }

    const colabsCandidateInJobOffers = await getSubDocumentsWithQuerys(
      'events',
      id,
      'staff',
      [
        { field: 'idJobOffers', operator: '==', value: idJob },
        { field: 'status', operator: '!=', value: 11 },
      ],
      1,
    )

    if (Object.keys(colabsCandidateInJobOffers || {}).length > 0) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Vaga com Colaboradores',
          msg: `Não é possível deletar esta vaga, pois existem colaboradores cadastrados nela. Por favor, cancele as candidaturas antes de tentar deletar.`,
        },
      ])
    }

    const job = (dataContext?.jobOffers || []).filter((jobOffers) => jobOffers.id === idJob)[0]

    await deleteSubDocument('events', id, 'jobOffers', idJob)
    console.log([job.idPosition])
    await updateArrayInDocument('events', id, 'idPositions', [job.idPosition], false)

    await setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: 'Vaga Deletada',
        msg: `A Vaga ${infosContext.positionsById[job.idPosition]} do dia ${job.date} foi deletada do evento ${formContext?.name} com sucesso.`,
      },
    ])

    await FetchGetInfos()
    await FetchGetdatas()
    await SetHistoryEvent(
      id,
      user.user.nome,
      `Vaga Deletada: ${infosContext.positionsById[job.idPosition]} - ${job.date} ${reason !== '' && `|| Motivo: ${reason}`}`,
    )
  }

  const ChangeStatusJobOffers = async (idJob, status) => {
    if (!user.user.permissions.jobOffers_openClose) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Permissão Negada',
          msg: 'Você não possui permissão para realizar essa ação.',
        },
      ])
    }

    const job = (dataContext?.jobOffers || []).filter((jobOffers) => jobOffers.id === idJob)[0]

    const newJob = { status }

    await updateSubDocument('events', id, 'jobOffers', idJob, newJob)

    await FetchGetdatas()

    await setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: 'Situação Alterada',
        msg: `A Vaga ${infosContext.positionsById[job.idPosition]} do dia ${job.date} foi alterada.`,
      },
    ])

    await FetchGetInfos()
    await FetchGetdatas()

    await SetHistoryEvent(
      id,
      user.user.nome,
      `Situação da vaga  ${infosContext.positionsById[job.idPosition]} - ${job.date} alterado para:  ${status}`,
    )
  }

  const SelectValuesFormJob = async (jobOffer, copy) => {
    const newJob = { ...(!copy && { id: jobOffer?.id }), ...schemaJobOffer(jobOffer), status: 'closed' }

    if (Object.keys(newJob).length === 0) {
      return
    }

    console.log('Select JobOffer: ', newJob)

    await setFormContext((prev) => ({ ...prev, formJobOffer: newJob }))
    setModal(true)
  }

  const HandleDelJobOffers = async (idJob) => {
    await TryCatch(
      () => {
        deleteJobOffers(idJob)
      },
      setLoading,
      setAlerts,
    )
  }

  const HandleChangeStatusJobOffers = async (idJob, status) => {
    // salva no contexto e no firestore
    await TryCatch(
      () => {
        ChangeStatusJobOffers(idJob, status)
      },
      setLoading,
      setAlerts,
    )
  }

  const HandleSelectValuesFormJob = async (jobOffer, copy) => {
    await TryCatch(
      () => {
        SelectValuesFormJob(jobOffer, copy)
      },
      setLoading,
      setAlerts,
    )
  }

  useEffect(() => {
    const fetchData = async () => {
      await FetchGetInfos()
      await FetchGetdatas()
    }
    fetchData()
  }, [formContext.openAllJobs, FetchGetInfos, FetchGetdatas])

  return {
    id,
    idJobEdit,
    SetIdJobEdit,
    loading,
    search,
    setSearch,
    formContext,
    setFormContext,
    FetchGetInfos,
    FetchGetdatas,
    infosContext,
    setInfosContext,
    dataContext,
    addVagas,
    setAddVagas,
    modal,
    setModal,
    HandleDelJobOffers,
    HandleChangeStatusJobOffers,
    HandleSelectValuesFormJob,
  }
}
