import { useNavigate, useParams } from 'react-router-dom'
import { useState } from 'react'

import { useGlobalContext } from '../../../../../context/ContextGlobal'
import { updateDocument } from '../../../../../firebase/firestore'
import { Path } from '../../../../../router/paths'
import { TryCatch } from '../../../../../utils/general'

const useEditPosition = () => {
  const { id } = useParams()
  const {
    // user,
    setAlerts,
    dataContext,
    // setDataContext,
    // infosContext,
    // setInfosContext,
    // formContext,
    // setFormContext,
    loading,
    setLoading,
    // statusLoading,
    // setStatusLoading,
  } = useGlobalContext()

  const navigate = useNavigate()

  const [newItem, setNewItem] = useState(Object.values(dataContext?.cargos || {}).find((i) => i.id === id))
  const [permissions] = useState(newItem?.permissions || {})

  const editDocument = async () => {
    const newCargo = { ...newItem, permissions: { ...permissions } }
    await updateDocument('cargos', id, newItem)

    setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: 'Cargos Editado',
        msg: `O Cargo "${newCargo.nome}" foi editado com sucesso.`,
      },
    ])
    return navigate(Path.AdminCargos)
  }

  const handleEditDocument = async () => {
    await TryCatch(editDocument, setLoading, setAlerts)
  }

  return { loading, newItem, dataContext, setNewItem, handleEditDocument }
}

export default useEditPosition
