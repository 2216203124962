import React from 'react'

import { Page } from '../../../../components/layouts/page'
import { Column } from '../../../../components/layouts/columns'
import { FormPosition } from './form/formPosition'
import useEditPosition from './hooks/useEditPosition'

export const AdminEditPositions = () => {
  const { loading, newItem, dataContext, setNewItem, handleEditDocument } = useEditPosition()

  return (
    <Page.Section className="is-fullheight-with-navbar">
      {/* <Form.Root action={() => {EditarDocumento();}}> */}
      <Column.Root>
        <Column.Content>
          <p className="subtitle is-size-3">Editar Cargo</p>
        </Column.Content>
      </Column.Root>
      <FormPosition
        loading={loading}
        values={newItem}
        setValues={setNewItem}
        options={dataContext}
        action={handleEditDocument}
        disabledList={{}}
      />
      {/* </Form.Root > */}
    </Page.Section>
  )
}
