// import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useGlobalContext } from '../../../../context/ContextGlobal'
import { generateIdDocument, getDocuments, getDocumentsWithQuery, setDocument } from '../../../../firebase/firestore'
import { uploadFile } from '../../../../firebase/storage'
import { DateTimeNow } from '../../../../utils/dates'
import { TryCatch } from '../../../../utils/general'
import { validateNewCollaboratorSchema } from '../../clients/_utils'
import { ClientOmie } from '../../../../api/omie'
import { createSchemaColabOmie } from '../../../../api/omie/schemas'
import { NewUserAuth } from '../../../../utils/requests'
import { Path } from '../../../../router/paths'

export const useAddAdminCollaborators = () => {
  const {
    user,
    setAlerts,
    loading,
    setLoading,
    // dataContext,
    // setDataContext, // contexto que guarda dados de lista para a pagina
    // infosContext,
    setInfosContext, // contexto que guarda dados adicionais para a pagina funcionar
    formContext,
    setFormContext, // contexto que guarda dados de formularios da pagina
  } = useGlobalContext()

  const navigate = useNavigate()

  const getInfos = async () => {
    // coleta as permissões globais em CONFIG/PERMISSIONS
    const getConsents = await getDocuments('consentimentos')
    const getDocs = await getDocuments('documentos')
    const getPositions = await getDocuments('cargos')

    const formattedConsents = getConsents.map(({ id, nome: name }) => {
      return {
        id,
        name,
      }
    })

    const formattedDocs = getDocs.map(({ id, nome: name }) => {
      return {
        id,
        name,
      }
    })
    const formattedPositions = getPositions.map(({ id, nome: name, consentimentos, documentos }) => {
      return {
        id,
        name,
        consentimentos,
        documentos,
      }
    })

    setInfosContext({
      consentimentos: formattedConsents,
      documentos: formattedDocs,
      cargos: formattedPositions,
    })
  }

  const addNewCollaborator = async () => {
    const newCollaborator = {
      name: formContext?.nome,
      photoUrl: formContext?.url_foto,
      cpf: formContext?.cpf,
      personal: {
        socialName: formContext?.nomeSocial,
        document: formContext?.documento,
        docType: formContext?.tipoDocumento,
        birthday: formContext?.dtNascimento,
        civilStatus: formContext?.estadoCivil,
      },
      address: {
        street: formContext?.logradouro,
        number: formContext?.numeroEndereco,
        district: formContext?.bairro,
        complement: formContext?.complemento || '',
        zipcode: formContext?.cep,
        city: formContext?.cidade,
        state: formContext?.uf,
      },
      contact: { phone: formContext?.celular, emergencyContacts: formContext?.emergencyContacts },
      banking: {
        account: formContext?.conta,
        agency: formContext?.agencia,
        bank: formContext?.banco,
        pix: formContext?.pix,
      },
      consents: { ...formContext?.consentimentos },
      documents: { ...formContext?.documentos },
      positions: { ...formContext?.cargos },
      email: formContext?.email,
      dt_created: DateTimeNow(),
      // dt_register: DateTimeNow(),
      // dt_last_login: DateTimeNow(),
      created_by: user?.user?.nome,
      // version_app: '1.0.0',
      status: 1,
      // notification_token: 'teste',
    }

    if (!validateNewCollaboratorSchema(newCollaborator).isValidSchema) {
      const { error } = validateNewCollaboratorSchema(newCollaborator)

      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Dados incompletos',
          msg: `Para continuar com o cadastro do colaborator verifique os seguintes campos: ${error}`,
        },
      ])
    }

    const setPassword = window.prompt('Digite uma senha padrão para o colaborador realizar o primeiro login', '')

    if (!setPassword) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Senha obrigatória',
          msg: `Para continuar com o cadastro é necessário criar uma senha padrão, ex: keap123.`,
        },
      ])
    }

    const newUserAuthResponse = await NewUserAuth(newCollaborator, setAlerts, setPassword)

    if (newUserAuthResponse.error) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Error de autenticação',
          msg: `Houve um erro na autenticação: ${newUserAuthResponse.error}.`,
        },
      ])
    }

    if (newUserAuthResponse.userExists) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Usuário existente',
          msg: `O usuário já existe .`,
        },
      ])
    }

    let uuid = newUserAuthResponse.uid

    if (!uuid) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'uuid inexistente',
          msg: `O ${uuid} não foi encontrado .`,
        },
      ])
    }

    const cpfExists = await getDocumentsWithQuery('colaboradores', 'cpf', '==', newCollaborator.cpf)
    const collaboratorId = await generateIdDocument('colaboradores')

    console.log(cpfExists)
    console.log('collaboratorId', collaboratorId)

    if (cpfExists.length > 0) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'CPF já cadastrado',
          msg: `O CPF ${newCollaborator.cpf} informado já existe na base de dados.`,
        },
      ])
    }

    const imageTimestamp = new Date().toISOString()

    const pathUploadFile = `collaborators/${imageTimestamp}/foto_${imageTimestamp}`

    if (newCollaborator.photoUrl) {
      const photoUrl = await uploadFile(newCollaborator.photoUrl, pathUploadFile)
      newCollaborator.photoUrl = photoUrl
    }

    // para criar um cliente omie, ele precisa do id de integração
    const newClienteOmie = createSchemaColabOmie({ ...formContext, id: uuid, tags: 'Colaborador' })

    console.log(newClienteOmie)
    let createClientOmie
    let cCodClientOmie
    try {
      // tenta criar um cliente na omie
      createClientOmie = await ClientOmie().incluirCliente([newClienteOmie])
    } catch (error) {
      // se eele retornar que ja existe e é um novo cadastro, então segue o fluxo, se não da erro
      if (error.message.includes('102') && cpfExists.length === 0) {
        cCodClientOmie = String(error.message).split('nCod')[1].match(/\d+/)[0]
      } else if (error.message.includes('101') && cpfExists.length === 0) {
        const errorMessage = String(error.message).toLowerCase()
        console.log(errorMessage)
        cCodClientOmie = errorMessage.match(/com o id \[([^\]]+)\]/)[1]
        uuid = errorMessage.match(/código de integração \[([^\]]+)\]/)[1]
        console.log(cCodClientOmie)
        console.log(uuid)
      } else {
        throw new Error(error.message)
      }
    }
    // se não deu error, então ele pega do response
    if (createClientOmie) {
      cCodClientOmie = createClientOmie.codigo_cliente_omie
    }

    const newCollaboratorId = await setDocument('colaboradores', uuid, {
      ...newCollaborator,
      idClientOmie: cCodClientOmie,
    })

    setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: 'Colaborador Criado',
        msg: `O colaborador ${newCollaborator.name} foi criado com sucesso.`,
      },
    ])

    await setFormContext({})

    navigate(Path.EditAdminCollaborator(newCollaboratorId))
  }

  const handleAddCollaborators = async () => {
    await TryCatch(addNewCollaborator, setLoading, setAlerts)
  }

  const clearFormContextOnRender = () => {
    const initialFormState = { estadoCivil: '', tipoDocumento: '', uf: '' }

    setFormContext(initialFormState)
  }

  const FetchInfos = async () => {
    await TryCatch(getInfos, setLoading, setAlerts)
  }

  useEffect(() => {
    FetchInfos()
  }, [])

  useEffect(() => {
    clearFormContextOnRender()
  }, [setFormContext])

  return {
    loading,
    formContext,
    setFormContext,
    handleAddCollaborators,
  }
}
