import React from 'react'

import { Column } from '../../../../components/layouts/columns'
import { Button } from '../../../../components/ui/button'
import { Input } from '../../../../components/ui/input'
import { Select } from '../../../../components/ui/select'
import { Path } from '../../../../router/paths'
import { Table } from '../../../../components/ui/table'
import { useCollaboratorPositionsForm } from '../_hooks/useCollaboratorPositionsForm'
import { Icon } from '../../../../components/icons'

export const CollaboratorsPositionsForm = () => {
  const {
    loading,
    search,
    setSearch,
    position,
    isPositionEmpty,
    setPosition,
    positionList,
    getPositionStatusType,
    getConsentStatusType,
    getDocumentStatusType,
    handleMainPosition,
    positionsAdded,
    blockUnblockPosition,
    consents,
    documents,
    addPositionToList,
    deletePositionFromList,
    approvePosition,
    submitFormDisabled,
    HandleResendAttachment,
  } = useCollaboratorPositionsForm()

  return (
    <>
      <Column.Root>
        <Column.Content>
          <Column.Root className="is-mobile mr-6 pr-2">
            <Column.Content>
              <Input.Root className="mb-5">
                <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
                  Cargos
                </Input.Label>
                <Select.Root>
                  <Select.Content
                    color="is-grey"
                    className="is-fullwidth is-rounded"
                    value={position}
                    setValue={setPosition}
                    disabled={false}
                  >
                    <Select.Search value={search} setValue={setSearch} />
                    {Object.keys(positionList).map((key, index) => {
                      return <Select.Option key={index} value={key} title={positionList[key].name} />
                    })}
                  </Select.Content>
                </Select.Root>
              </Input.Root>
            </Column.Content>
            <Column.Content size="is-1 mr-5">
              <Button.Bt
                className="mt-5 is-rounded"
                color="is-dark"
                colorText=""
                action={() => {
                  addPositionToList(position)
                }}
                disabled={isPositionEmpty} // tava pegando false por default, verificar depois
                loading={loading}
              >
                Adicionar
              </Button.Bt>
            </Column.Content>
          </Column.Root>

          {Object.keys(positionsAdded).length > 0 && (
            <>
              <Column.Root>
                <Column.Content>
                  <h2 className="subtitle is-size-4">Cargos</h2>
                </Column.Content>
              </Column.Root>

              <div className="box" style={{ marginTop: 0 }}>
                <div className="table-container">
                  <Table.Root className="is-fullwidth has-text-centered mb-5 mt-2">
                    <Table.Head>
                      <Table.Row>
                        <Table.Cell>Nome</Table.Cell>
                        <Table.Cell>Principal</Table.Cell>
                        <Table.Cell>Status</Table.Cell>
                        <Table.Cell>Ações</Table.Cell>
                      </Table.Row>
                    </Table.Head>
                    <Table.Body>
                      {Object.keys(positionsAdded).map((positionAdded) => {
                        const positionId = positionsAdded[positionAdded].id

                        return (
                          <Table.Row key={positionId}>
                            <Table.Cell>{positionsAdded[positionAdded].name}</Table.Cell>
                            <Table.Cell>
                              <label className="radio" htmlFor={positionId + '-radio'}>
                                {''}
                              </label>
                              <input
                                id={positionId + '-radio'}
                                type="radio"
                                checked={positionsAdded[positionAdded].main}
                                name="cargoPrincipal"
                                onChange={() => handleMainPosition(positionId)}
                              />
                            </Table.Cell>
                            <Table.Cell>{getPositionStatusType(positionsAdded[positionAdded].status)}</Table.Cell>
                            <Table.Cell className="is-flex is-justify-content-center gap-2">
                              <Button.Content>
                                <Button.Bt
                                  size="is-small"
                                  color={positionsAdded[positionAdded].status === 9 ? 'is-danger' : 'is-success'}
                                  action={() => blockUnblockPosition(positionId)}
                                  loading={loading}
                                >
                                  <Icon size={15}>unlock</Icon>
                                </Button.Bt>
                              </Button.Content>
                              <Button.Content>
                                <Button.Bt
                                  size="is-small"
                                  color="is-success"
                                  action={() => approvePosition(positionId)}
                                  loading={loading}
                                >
                                  <Icon size={15}>check</Icon>
                                </Button.Bt>
                              </Button.Content>
                              <Button.Content>
                                <Button.Bt
                                  size="is-small"
                                  color="is-danger"
                                  action={() => deletePositionFromList(positionId)}
                                  loading={loading}
                                >
                                  <Icon size={15}>trash</Icon>
                                </Button.Bt>
                              </Button.Content>
                            </Table.Cell>
                          </Table.Row>
                        )
                      })}
                    </Table.Body>
                  </Table.Root>
                </div>
              </div>
            </>
          )}

          {Object.keys(consents).length > 0 && (
            <>
              <Column.Root className="mt-3">
                <Column.Content>
                  <h2 className="subtitle is-size-4">Consentimentos</h2>
                </Column.Content>
              </Column.Root>
              <div className="box" style={{ marginTop: 0 }}>
                <div className="table-container">
                  <Table.Root className="is-fullwidth has-text-centered mb-5 mt-2">
                    <Table.Head>
                      <Table.Row>
                        <Table.Cell>Nome</Table.Cell>
                        <Table.Cell>Data de assinatura</Table.Cell>
                        <Table.Cell>Status</Table.Cell>
                      </Table.Row>
                    </Table.Head>
                    <Table.Body>
                      {Object.keys(consents).map((consent) => {
                        const consentId = consents[consent].id

                        return (
                          <Table.Row key={consentId}>
                            <Table.Cell>{consents[consent].name}</Table.Cell>
                            <Table.Cell>{consents[consent].signDate}</Table.Cell>
                            <Table.Cell>{getConsentStatusType(consents[consent].status)}</Table.Cell>
                          </Table.Row>
                        )
                      })}
                    </Table.Body>
                  </Table.Root>
                </div>
              </div>
            </>
          )}

          {Object.keys(documents).length > 0 && (
            <>
              <Column.Root className="mt-3">
                <Column.Content>
                  <h2 className="subtitle is-size-4">Documentos</h2>
                </Column.Content>
              </Column.Root>
              <div className="box" style={{ marginTop: 0 }}>
                <div className="table-container">
                  <Table.Root className="is-fullwidth has-text-centered mb-5 mt-2">
                    <Table.Head>
                      <Table.Row>
                        <Table.Cell>Nome</Table.Cell>
                        <Table.Cell>Data de envio</Table.Cell>
                        <Table.Cell>Status</Table.Cell>
                        <Table.Cell>Ações</Table.Cell>
                      </Table.Row>
                    </Table.Head>
                    <Table.Body>
                      {Object.keys(documents).map((document) => {
                        const documentId = documents[document].id

                        return (
                          <Table.Row key={documentId}>
                            <Table.Cell>{documents[document].name}</Table.Cell>
                            <Table.Cell>{documents[document].sendDate}</Table.Cell>
                            <Table.Cell>{getDocumentStatusType(documents[document].status)}</Table.Cell>
                            <Table.Cell className="is-vcentered">
                              <div>
                                <Button.Root align="centered">
                                  <Button.Content>
                                    <Button.A
                                      size="is-small"
                                      link={documents[document].urlSubmit}
                                      loading={loading}
                                      title="Abrir Anexo"
                                      openNew
                                    >
                                      <Icon size={15}>file-blank</Icon>
                                    </Button.A>
                                  </Button.Content>
                                  <Button.Content>
                                    <Button.Bt
                                      size="is-small"
                                      color="is-danger"
                                      action={async () => {
                                        await HandleResendAttachment(document, documents[document])
                                      }}
                                      loading={loading}
                                      title="Recusar Anexo"
                                    >
                                      <Icon size={15}>file-slash</Icon>
                                    </Button.Bt>
                                  </Button.Content>
                                </Button.Root>
                              </div>
                            </Table.Cell>
                          </Table.Row>
                        )
                      })}
                    </Table.Body>
                  </Table.Root>
                </div>
              </div>
            </>
          )}
        </Column.Content>
      </Column.Root>
      <Column.Root className="mt-5 mb-6">
        <Column.Content>
          <Button.Root align="right">
            <Button.Content>
              <Button.A color="is-light" link={Path.AdminCollaborators} loading={loading}>
                Cancelar
              </Button.A>
            </Button.Content>
            <Button.Content>
              <Button.Bt
                className=""
                color="is-success"
                colorText="has-text-white"
                type={'submit'}
                loading={loading}
                disabled={!submitFormDisabled} // tava pegando false por default, verificar depois
              >
                Salvar
              </Button.Bt>
            </Button.Content>
          </Button.Root>
        </Column.Content>
      </Column.Root>
    </>
  )
}
