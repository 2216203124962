import { useNavigate } from 'react-router-dom'

import { useGlobalContext } from '../../../../../context/ContextGlobal'
import { addDocument } from '../../../../../firebase/firestore'
import { Path } from '../../../../../router/paths'
import { DateTimeNow } from '../../../../../utils/dates'
import { TryCatch } from '../../../../../utils/general'

const useAddDocuments = () => {
  const {
    user,
    setAlerts,
    // dataContext,
    // setDataContext,
    // infosContext,
    // setInfosContext,
    formContext,
    setFormContext,
    loading,
    setLoading,
    // statusLoading,
    // setStatusLoading,
  } = useGlobalContext()

  const navigate = useNavigate()

  const addDocumentation = async () => {
    const newDocumentation = {
      ...formContext,
      created_by: user?.user?.nome,
      dt_created: DateTimeNow(),
    }
    await addDocument('documentos', newDocumentation)
    setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: 'Docuemnto Criado',
        msg: 'Novo Docuemnto criado com sucesso.',
      },
    ])
    setFormContext({})
    return navigate(Path.AdminDocuments)
  }

  const handleAddDocumentation = async () => {
    await TryCatch(addDocumentation, setLoading, setAlerts)
  }

  return { user, loading, formContext, setFormContext, handleAddDocumentation }
}

export default useAddDocuments
