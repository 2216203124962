import React from 'react'

import { FormAdminUsers } from './form/formAdminUsers'
import { Page } from '../../../../components/layouts/page'
import { Column } from '../../../../components/layouts/columns'
import { Form } from '../../../../components/ui/form'
import useAdminEditUser from './hooks/useAdminEditUser'

export const AdminEditUsers = () => {
  const { uid, loading, formContext, infosContext, setFormContext, handleEditUser } = useAdminEditUser()
  return (
    <Page.Section className="is-fullheight-with-navbar">
      <Form.Root
        action={() => {
          handleEditUser()
        }}
      >
        <Column.Root>
          <Column.Content>
            <p className="subtitle is-size-3">Usuário</p>
          </Column.Content>
        </Column.Root>
        <FormAdminUsers
          loading={loading}
          values={formContext}
          setValues={setFormContext}
          permissions={infosContext?.sectors || []}
          disabledList={{ email: true }}
          uid={uid}
        />
      </Form.Root>
    </Page.Section>
  )
}
