import { useEffect, useState } from 'react'

import { useGlobalContext } from '../../../../../context/ContextGlobal'
import { getDocuments, deleteDocument } from '../../../../../firebase/firestore'
import { TryCatch } from '../../../../../utils/general'

const useConsents = () => {
  const {
    user,
    setAlerts,
    dataContext,
    setDataContext,
    // infosContext, todas as informações para a página funcionar
    // setInfosContext,
    // formContext,
    setFormContext,
    loading,
    setLoading,
    // statusLoading,
    // setStatusLoading,
  } = useGlobalContext()
  const [search, setSearch] = useState('')
  const consentimentos = dataContext?.consentimentos || []

  const getData = async () => {
    const getCargos = await getDocuments('cargos')
    const getConsentimentos = await getDocuments('consentimentos')
    const newConsentimentos = getConsentimentos.map((item) => {
      const inCargo = getCargos.some((cargos) => {
        const consentimentoIds = cargos?.consentimentos || []
        const found = consentimentoIds.includes(item.id)
        return found
      })
      return { ...item, blocked: inCargo } // Adiciona se ele é bloqueado para deletar ou não
    })

    setDataContext({ consentimentos: newConsentimentos })
  }

  const fetchGetData = async () => {
    await TryCatch(getData, setLoading, setAlerts)
  }

  // deleta o usuario no cliente e se não tiver cadastro em cliente, ele apaga o usuario do autenticador
  const deleteConsent = async (consent) => {
    if (!user?.user?.permissions?.consentimentos_excluir) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Permissão Negada',
          msg: 'Você não possui permissão para apagar Consentimentos',
        },
      ])
    }

    const blocked = consent?.blocked || false
    if (blocked) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Consentimento em uso',
          msg: 'Você não pode deletar esse consentimento, pois ele está em uso por algum cargo',
        },
      ])
    }

    const idConsent = consent?.id || null

    if (!idConsent) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'ID do consentimento não encontrado',
          msg: 'Não conseguimos achar o ID do consentimento. Tente novamente mais tarde.',
        },
      ])
    }

    await deleteDocument('consentimentos', idConsent)
    await getData()
    return setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: 'Consentimento Excluído',
        msg: 'Consentimento excluído da sua base de dados',
      },
    ])
  }

  const handleDeleteConsent = async (consent) => {
    await TryCatch(
      async () => {
        deleteConsent(consent)
      },
      setLoading,
      setAlerts,
    )
  }

  const consentimentosFiltered =
    search !== ''
      ? Object.values(consentimentos).filter((consent) => {
          const searchString = `${consent?.name}|${consent?.txtButton}|${consent?.termo}`.toLowerCase()
          return searchString.includes(search.toLocaleLowerCase())
        })
      : Object.values(consentimentos)

  useEffect(() => {
    fetchGetData()
    setFormContext({})
  }, [])

  return { search, setSearch, loading, consentimentosFiltered, fetchGetData, handleDeleteConsent }
}

export default useConsents
