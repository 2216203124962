import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { useGlobalContext } from '../../../../context/ContextGlobal'
import { updateDocument } from '../../../../firebase/firestore'
import { TryCatch } from '../../../../utils/general'

export const useCollaboratorPositionsForm = () => {
  const { loading, setAlerts, infosContext, formContext, setFormContext, setLoading, seAlerts } = useGlobalContext()

  const [search, setSearch] = useState('')

  const { id } = useParams()

  // cargo
  const [position, setPosition] = useState('')
  const isPositionEmpty = position === ''
  const positionList = [...(infosContext?.cargos || [])].reduce((acc, item) => {
    acc[item.id] = { ...item }
    return acc
  }, {})

  const positionsAdded = formContext?.cargos ?? {}

  const consents = formContext?.consentimentos ?? {}
  const documents = formContext?.documentos ?? {}

  const submitFormDisabled = Object.keys(positionsAdded).length > 0

  const getPositionStatusType = (position) => {
    let positionType

    switch (position) {
      case 1:
        positionType = 'Verificado'
        break
      case 9:
        positionType = 'Bloqueado'
        break
      default:
        positionType = 'Em Análise'
        break
    }

    return positionType
  }

  const getConsentStatusType = (consent) => {
    let positionType

    switch (consent) {
      case 1:
        positionType = 'Assinado'
        break
      case 9:
        positionType = 'Revogado'
        break
      default:
        positionType = 'Pendente'
        break
    }

    return positionType
  }

  const getDocumentStatusType = (document) => {
    let documentType

    switch (document) {
      case true:
        documentType = 'Enviado'
        break
      default:
        documentType = 'Pendente'
    }

    return documentType
  }

  const addPositionToList = (key) => {
    const position = formContext?.cargos
    const currentPosition = infosContext?.cargos?.find((currentPosition) => currentPosition.id === key)
    const currentPositionConsentIds = currentPosition?.consentimentos
    const currentPositionDocumentIds = currentPosition?.documentos

    currentPositionConsentIds.map((currentPositionId) => {
      const currentPositionConsent = infosContext?.consentimentos?.find((consent) => consent.id === currentPositionId)

      const currentPositionConsentContent = {
        name: currentPositionConsent.name,
        signDate: '-------',
        status: 0,
      }

      setFormContext((prevState) => {
        return {
          ...prevState,
          consentimentos: {
            ...prevState.consentimentos,
            [currentPositionConsent.id]: {
              ...currentPositionConsentContent,
            },
          },
        }
      })

      return {
        currentPositionConsentContent,
      }
    })

    currentPositionDocumentIds.map((currentPositionId) => {
      const currentPositionDocument = infosContext?.documentos?.find((document) => document.id === currentPositionId)

      const currentPositionDocumentContent = {
        name: currentPositionDocument.name,
        sendDate: '-------',
        status: false,
      }

      setFormContext((prevState) => {
        return {
          ...prevState,
          documentos: {
            ...prevState.documentos,
            [currentPositionDocument.id]: {
              ...currentPositionDocumentContent,
            },
          },
        }
      })

      return {
        currentPositionDocumentContent,
      }
    })

    if (position && Object.keys(position).includes(key)) {
      return setAlerts((prev) => [
        ...prev,
        {
          title: 'Cargo já adicionado',
          msg: 'É permitido inserir apenas cargos diferentes',
          type: 'error',
        },
      ])
    }

    return setFormContext((prevState) => {
      const isMainAsDefault = Object.keys(prevState.cargos).length < 1

      return {
        ...prevState,
        cargos: {
          ...prevState.cargos,
          [key]: {
            id: key,
            name: positionList[key].name,
            main: isMainAsDefault,
            status: 0,
            score: 0,
          },
        },
      }
    })
  }

  const approvePosition = (key) => {
    return setFormContext((prevState) => {
      return {
        ...prevState,
        cargos: {
          ...prevState.cargos,
          [key]: {
            ...prevState.cargos[key],
            status: 1,
          },
        },
      }
    })
  }

  const blockUnblockPosition = (key) => {
    return setFormContext((prevState) => {
      const currentPositionStatus = prevState.cargos[key].status
      const newPositionStatus = currentPositionStatus === 9 ? 1 : 9 // Bloqueado para verificado e vice-versa

      return {
        ...prevState,
        cargos: {
          ...prevState.cargos,
          [key]: {
            ...prevState.cargos[key],
            status: newPositionStatus,
          },
        },
      }
    })
  }

  const blockUnblockCollaborator = () => {
    return setFormContext((prevState) => {
      const currentCollaboratorStatus = prevState.status
      const newCollaboratorStatus = currentCollaboratorStatus === 9 ? 1 : 9 // Bloqueado para verificado e vice-versa
      return {
        ...prevState,
        status: newCollaboratorStatus,
      }
    })
  }

  const deletePositionFromList = (key) => {
    return setFormContext((prevState) => {
      const newPositionList = Object.fromEntries(
        Object.entries(prevState.cargos).filter(([entryKey]) => entryKey !== key),
      )

      const currentPosition = infosContext?.cargos?.find((currentPosition) => currentPosition.id === key)
      const currentPositionConsentIds = currentPosition?.consentimentos
      const currentPositionDocumentIds = currentPosition?.documentos
      let newConsentsList
      let newDocumentsList

      currentPositionConsentIds.map((currentPositionConsentId) => {
        return (newConsentsList = Object.fromEntries(
          Object.entries(prevState.consentimentos).filter(([entryKey]) => entryKey !== currentPositionConsentId),
        ))
      })

      currentPositionDocumentIds.map((currentPositionDocumentId) => {
        return (newDocumentsList = Object.fromEntries(
          Object.entries(prevState.documentos).filter(([entryKey]) => entryKey !== currentPositionDocumentId),
        ))
      })

      return {
        ...prevState,
        cargos: newPositionList,
        documentos: newDocumentsList,
        consentimentos: newConsentsList,
      }
    })
  }

  const resendAttachment = async (idDocument, document) => {
    await updateDocument('colaboradores', id, {
      [`documents.${idDocument}`]: { ...document, status: false, urlSubmit: '' },
    })
    return setAlerts((prev) => [
      ...prev,
      {
        title: 'Documento Recusado',
        msg: 'Foi enviado ao colaborador para que reenvie o documento novamente.',
        type: 'success',
      },
    ])
  }

  const HandleResendAttachment = async (idDocument, document) => {
    await TryCatch(
      async () => {
        await resendAttachment(idDocument, document)
      },
      setLoading,
      seAlerts,
    )
  }

  // Função para definir uma linha como principal e atualizar o contexto
  const handleMainPosition = (id) => {
    // Atualiza o contexto, configurando a opção selecionada como principal
    setFormContext((prevState) => {
      const cargosAtualizados = Object.keys(prevState.cargos).reduce((acc, key) => {
        acc[key] = {
          ...prevState.cargos[key],
          main: key === String(id), // Marca o item como principal se o ID for o mesmo
        }
        return acc
      }, {})

      return {
        ...prevState,
        cargos: cargosAtualizados,
      }
    })
  }

  return {
    loading,
    search,
    setSearch,
    position,
    isPositionEmpty,
    setPosition,
    positionList,
    getPositionStatusType,
    getConsentStatusType,
    getDocumentStatusType,
    positionsAdded,
    blockUnblockPosition,
    blockUnblockCollaborator,
    consents,
    documents,
    addPositionToList,
    deletePositionFromList,
    approvePosition,
    submitFormDisabled,
    handleMainPosition,
    HandleResendAttachment,
  }
}
