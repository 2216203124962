import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { updateDocument } from '../../../../firebase/firestore'
import { useGlobalContext } from '../../../../context/ContextGlobal'
import { TryCatch } from '../../../../utils/general'

export const useValidateCollaboratorModal = () => {
  const [openModal, setOpenModal] = useState(false)

  const { setAlerts, formContext, setFormContext, setLoading, loading } = useGlobalContext()

  const { id } = useParams()

  const modalCollaboratorSubmitDisabled = formContext.modalCollaboratorSubmitDisabled ?? true

  const handleOpenValidateModal = () => {
    setOpenModal(!openModal)
  }

  const clearUpdatedInBulkFlags = () => {
    setFormContext((prevContext) => {
      // Copia a estrutura de `inEdition` atual
      const updatedInEdition = { ...prevContext.inEdition }

      // Remove a propriedade `updatedInBulk` de todos os itens
      Object.keys(updatedInEdition).forEach((itemKey) => {
        const item = updatedInEdition[itemKey]

        if (Array.isArray(item)) {
          // Caso seja um array (ex: emergencyContacts), remove a flag de todos os contatos
          item.forEach((contact) => {
            Object.keys(contact).forEach((fieldKey) => {
              if (contact[fieldKey].updatedInBulk) {
                delete contact[fieldKey].updatedInBulk
              }
            })
          })
        } else if (item.updatedInBulk) {
          // Caso seja um item singular, remove a flag diretamente
          delete item.updatedInBulk
        }
      })

      return {
        ...prevContext,
        inEdition: updatedInEdition,
      }
    })
  }

  const SendFieldsToFix = async () => {
    clearUpdatedInBulkFlags()

    const newInEdition = {
      inEdition: { ...(formContext?.inEdition ?? {}) },
      status: 2,
    }

    await updateDocument('colaboradores', id, newInEdition)

    setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: 'Campos selecionados',
        msg: `Os campos foram enviados para correção.`,
      },
    ])

    setFormContext((prev) => ({
      ...prev,
      ...newInEdition,
      validate: false,
    }))

    setOpenModal(false)
  }

  const handleSendFieldsToFix = async () => {
    await TryCatch(SendFieldsToFix, setLoading, setAlerts)
  }

  return {
    loading,
    openModal,
    setOpenModal,
    handleOpenValidateModal,
    handleSendFieldsToFix,
    modalCollaboratorSubmitDisabled,
  }
}
