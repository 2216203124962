/**
 * objeto que controla os caminhos de cada pagina
 *
 * Dependendo da kei passada, ele será uma function, e vc precisa passar os valores para gerar o path
 */

export const Path = {
  Login: '/',
  PasswordRecovery: 'esquecisenha',
  FirstAccess: (email) => `primeiroacesso/${email}`,
  SelectAccount: 'selecaoConta',

  // ADMINISTRATION
  AdministrationHome: '/administracao',

  // SETTINGS
  ASettings: '/administracao/configuracoes',

  // USERS
  AdminUsers: '/administracao/configuracoes/usuarios',
  AAddUsers: '/administracao/configuracoes/usuarios/adicionar',
  AEditUsers: (uid) => `/administracao/configuracoes/usuarios/${uid}`,

  // PLANS
  APlans: '/administracao/configuracoes/planos',
  AAddPlans: '/administracao/configuracoes/planos/adicionar',
  AEditPlans: (id) => `/administracao/configuracoes/planos/${id}`,

  // SECTORS
  ASectors: '/administracao/configuracoes/setores',
  AAddScetors: '/administracao/configuracoes/setores/adicionar',
  AEditSectors: (uid) => `/administracao/configuracoes/setores/${uid}`,

  // DOCUMENTS
  AdminDocuments: '/administracao/configuracoes/documentos',
  AdminAddDocuments: '/administracao/configuracoes/documentos/adicionar',
  AdminEditDocuments: (id) => `/administracao/configuracoes/documentos/${id}`,

  // CONSENTS
  AdminConsents: '/administracao/configuracoes/consentimentos',
  AdminAddConsents: '/administracao/configuracoes/consentimentos/adicionar',
  AdminEditConsents: (id) => `/administracao/configuracoes/consentimentos/${id}`,

  // CARGOS
  AdminCargos: '/administracao/configuracoes/cargos',
  AAddCargos: '/administracao/configuracoes/cargos/add',
  AdminEditCargos: (id) => `/administracao/configuracoes/cargos/${id}`,

  // CLIENTS
  AClients: '/administracao/clientes',
  AAdicionarClientes: '/administracao/clientes/adicionar',
  AdminClientEdit: (id) => `/administracao/clientes/editar/${id}`,
  AdminClientBilling: (id) => `/administracao/clientes/editar/${id}/faturamento`,
  AdminClientPayments: (id) => `/administracao/clientes/editar/${id}/pagamentos`,

  // COLLABORATOR
  AdminCollaborators: '/administracao/colaboradores',
  AddAdminCollaborators: '/administracao/colaboradores/adicionar',
  EditAdminCollaborator: (id) => `/administracao/colaboradores/${id}`,
  EditAdminCollaboratorPositions: (id) => `/administracao/colaboradores/${id}/cargos`,
  EditAdminCollaboratorFinancyOrPlans: (id) => `/administracao/colaboradores/${id}/financeiro-planos`,
  EditAdminCollaboratorCandidateEvents: (id) => `/administracao/colaboradores/${id}/eventos-candidatados`,
  EditAdminCollaboratorBlockedCompanies: (id) => `/administracao/colaboradores/${id}/empresas-bloqueadas`,
  EditAdminCollaboratorFavoriteCompanies: (id) => `/administracao/colaboradores/${id}/empresas-favoritadas`,

  // Area do Cliente
  ClientHome: '/cliente',

  ClientSettings: '/cliente/configuracoes',

  ClientPayments: '/cliente/configuracoes/pagamentos',

  ClientInformations: '/cliente/configuracoes/informacoes',

  ClientUsers: '/cliente/configuracoes/usuarios',
  ClientAddUsers: '/cliente/configuracoes/usuarios/adicionar',
  ClientEditUsers: (uid) => `/cliente/configuracoes/usuarios/editar/${uid}`,

  ClientSectors: '/cliente/configuracoes/setores',
  ClientAddSectors: '/cliente/configuracoes/setores/adicionar',
  ClientEditSectors: (uid) => `/cliente/configuracoes/setores/editar/${uid}`,

  ClientCitys: '/cliente/configuracoes/cidades',

  ClientEvents: '/cliente/eventos',
  ClientAddEvents: '/cliente/eventos/adicionar',
  ClientEditEvents: (id) => `/cliente/eventos/${id}`,
  ClientjobOffersEvents: (id) => `/cliente/eventos/${id}/vagas`,
  ClientStaffEvents: (id) => `/cliente/eventos/${id}/staff`,
  ClientHistoryEvent: (id) => `/cliente/eventos/${id}/historico`,
  ClientStaffFinally: (id) => `/cliente/eventos/${id}/finalizar`,

  ClientTeams: '/cliente/equipes',
  ClientAddTeams: '/cliente/equipes/adicionar',
  ClientEditTeams: (id) => `/cliente/equipes/editar/${id}`,

  ClientFavorites: '/cliente/favoritados',

  ClientBlockeds: '/cliente/bloqueados',
}
