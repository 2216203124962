import { useNavigate } from 'react-router-dom'
import { useState } from 'react'

import { useGlobalContext } from '../../../../../context/ContextGlobal'
import { addDocument } from '../../../../../firebase/firestore'
import { Path } from '../../../../../router/paths'
import { DateTimeNow } from '../../../../../utils/dates'
import { TryCatch } from '../../../../../utils/general'

const useAddPosition = () => {
  const {
    user,
    setAlerts,
    dataContext,
    // setDataContext,
    // infosContext,
    // setInfosContext,
    // formContext,
    // setFormContext,
    loading,
    setLoading,
    // statusLoading,
    // setStatusLoading,
  } = useGlobalContext()

  const navigate = useNavigate()
  const [newItem, setNewItem] = useState({
    documentos: [],
    consentimentos: [],
  })

  // adicionar um novo cargo
  const addItem = async () => {
    if (dataContext?.documentos.length > 0 && dataContext?.consentimentos.length > 0) {
      const newItemCompleted = {
        ...newItem,
        created_by: user?.user?.nome,
        dt_created: DateTimeNow(),
      }
      await addDocument('cargos', newItemCompleted)

      setAlerts((prev) => [
        ...prev,
        {
          type: 'success',
          title: 'Cargo Criado',
          msg: 'Novo cargo criado com sucesso.',
        },
      ])
      setNewItem({})
      return navigate(Path.AdminCargos)
    }
  }

  const handleAddItem = async () => {
    await TryCatch(addItem, setLoading, setAlerts)
  }

  return { loading, newItem, dataContext, setNewItem, handleAddItem }
}

export default useAddPosition
