import React from 'react'

import { FormAdminUsers } from './form/formAdminUsers'
import { Page } from '../../../../components/layouts/page'
import { Column } from '../../../../components/layouts/columns'
import { Form } from '../../../../components/ui/form'
import useAdminAddUser from './hooks/useAdminAddUser'

export const AdminAddUsers = () => {
  const { loading, formContext, setFormContext, infosContext, handleAddUser } = useAdminAddUser()

  return (
    <Page.Section className="is-fullheight-with-navbar">
      <Form.Root
        action={() => {
          handleAddUser()
        }}
      >
        <Column.Root>
          <Column.Content>
            <p className="subtitle is-size-3">Adicionar Usuário</p>
          </Column.Content>
        </Column.Root>
        <FormAdminUsers
          loading={loading}
          values={formContext}
          setValues={setFormContext}
          permissions={infosContext?.sectors || []}
          disabledList={{}}
        />
      </Form.Root>
    </Page.Section>
  )
}
