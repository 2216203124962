export const schemaUserKeap = (value) => {
  return {
    created_by: value?.create_by || '',
    dt_created: value?.dt_created || '',
    nome: value?.nome || '',
    descricao: value?.descricao || '',
    type: value?.type || '',
    cargo: value?.cargo || '',
    email: value?.email || '',
    cpf: value?.cpf || '',
    photoUrl: value?.photoUrl || '',
    sectorId: value?.sectorId || '',
    uid: value?.uid || '',
  }
}
